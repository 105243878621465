import React from "react";
import { BrowserRouter as Routers, Route, Routes } from "react-router-dom";

import About from "./pages/about/about";
import Home from "./pages/home/home";
import Category from "./pages/category/category";
import Footer from "./component/footer/footer";
import Login from "./pages/login/login";
import Dashboard from "./pages/dashboard/dashboard";
import Contact from "./pages/contact/contact";
import SingleProduct from "./pages/singleProduct/singleProduct";
import CreateProduct from "./pages/createProduct/createProduct";
import UniformCategory from "./pages/uniformCategory/uniformCategory";
import SingleBlog from "./pages/singleblog/singleresource";
import Resourcecenter from "./pages/resourcecenter/resourcecenter";
import CustomisedTshirt from "./pages/customisedTshirt/customisedTshirt";
import CreateBlog from "./pages/createBlog/createBlog";
import EditBlog from "./pages/EditBlog/EditBlog";
import EditProduct from "./pages/EditProduct/EditProduct";
import NotFound from "./pages/404/404";
import SizeGuide from "./component/size-guide/sizeguide";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import { Helmet } from "react-helmet";
import ThanksYou from "./component/ThanksYou/ThanksYou";
import { HelmetProvider } from "react-helmet-async";
import CustomMerchandise from "./pages/CustomMerchandise/CustomMerchandise";

function App() {
  return (
    <div className="App">
      <Helmet>
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-PH3ZR4VH');`}
        </script>
      </Helmet>
      <Routers>
        <HelmetProvider>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/about" exact element={<About />} />
            <Route path="/cooperateGifting" exact element={<Category />} />
            <Route
              path="/uniform-category/:category"
              exact
              element={<UniformCategory />}
            />
            <Route path="/login" exact element={<Login />} />
            <Route path="/dashboard" exact element={<Dashboard />} />
            <Route path="/contact" exact element={<Contact />} />
            <Route
              path="/product/:title/:id"
              exact
              element={<SingleProduct />}
            />
            <Route path="/product/:title" exact element={<SingleProduct />} />
            <Route path="/createProduct" exact element={<CreateProduct />} />
            <Route path="/blog" exact element={<Resourcecenter />} />
            <Route path="/ThankYou" exact element={<ThanksYou />} />
            <Route
              path="/customised-tshirt"
              exact
              element={<CustomisedTshirt />}
            />
            <Route path="/SingleBlog" exact element={<SingleBlog />} />
            <Route path="/editBlog/:id" exact element={<EditBlog />} />
            <Route path="/createBlog" exact element={<CreateBlog />} />
            <Route path="/editProduct/:id" exact element={<EditProduct />} />
            <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
            <Route
              path="/custom-merchandise"
              exact
              element={<CustomMerchandise />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </HelmetProvider>
      </Routers>
      <SizeGuide />
    </div>
  );
}

export default App;
