import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./createProduct.scss";
import Header from "../../component/header/header";
import Footer from "../../component/footer/footer";

const CreateProduct = () => {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [inputHandler, setInputHandler] = useState({
    title: " ",
    shortDescription: " ",
    description: " ",
    categoryName: "",
    subCategoryName: "",
  });
  const [image, setImage] = useState([]);

  const navigate = useNavigate();
  const [tags, setTags] = useState([]);
  const [img, setImg] = useState([]);

  const onChangeInputHandler = (e) => {
    const { name, value } = e.target;
    setInputHandler(() => {
      return { ...inputHandler, [name]: value };
    });
  };
  const history = useNavigate();

  const backToDashBoard = () => {
    history("/dashboard");
  };
  const addTags = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      setTags([...tags, event.target.value]);
      event.target.value = "";
    }
  };

  const removeImg = (index) => {
    setImg([...tags.filter((img) => img.indexOf(img) !== index)]);
  };

  const removeTags = (index) => {
    setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "getCategory"
      );
      console.log(response.data);
      setCategories(response.data);
    } catch (error) {
      console.error("Failed to fetch categories:", error);
    }
  };
  useEffect(() => {
    fetchCategories();
  }, []);

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token) {
    } else {
      history("/login");
    }
  }, []);

  const handleCategoryChange = (e) => {
    const selectedCategory = categories.find(
      (category) => category.name === e.target.value
    );
    setSubcategories(selectedCategory.subcategories);
    setInputHandler({ ...inputHandler, categoryName: e.target.value });
  };

  const handleSubCategoryChange = (e) => {
    setInputHandler({ ...inputHandler, subCategoryName: e.target.value });
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    const {
      title,
      shortDescription,
      description,
      categoryName,
      subCategoryName,
    } = inputHandler;

    if (
      !title ||
      !shortDescription ||
      !description ||
      !image ||
      !categoryName ||
      !subCategoryName
    ) {
      alert("Please fill in all required fields");
      return;
    }

    try {
      let uploadedImageUrls = [];

      // Upload images to Cloudinary
      if (Array.isArray(image) && image.length > 0) {
        const uploadPromises = image.map(async (img) => {
          if (img instanceof File || img instanceof Blob) {
            const formData = new FormData();
            formData.append("file", img);
            formData.append("upload_preset", "Aryan_Testing"); // Replace with your Cloudinary preset

            try {
              const { data } = await axios.post(
                "https://api.cloudinary.com/v1_1/dqvnizdtk/image/upload",
                formData
              );
              return data.secure_url || null;
            } catch (error) {
              console.error("Cloudinary Upload Error:", error);
              return null;
            }
          } else {
            console.error("Invalid image format:", img);
            return null;
          }
        });

        uploadedImageUrls = (await Promise.all(uploadPromises)).filter(Boolean);
      }

      // If no images uploaded successfully, alert and return
      if (uploadedImageUrls.length === 0) {
        alert("Image upload failed");
        return;
      }

      console.log("Uploaded Images:", uploadedImageUrls);

      // Create Product after Cloudinary upload
      const productConfig = {
        url: `${process.env.REACT_APP_BASE_URL}createProduct`,
        method: "post",
        data: {
          title,
          shortDescription,
          description,
          frontImagess: uploadedImageUrls, // Using Cloudinary image URLs
          categoryName,
          subCategoryName,
          colors: tags,
        },
      };

      const productResponse = await axios.request(productConfig);

      if (productResponse.data.success) {
        alert("Product Added Successfully");
        navigate("/dashboard");

        // Reset form state
        setInputHandler({
          title: "",
          shortDescription: "",
          description: "",
          categoryName: "",
          subCategoryName: "",
        });
        setImage([]);
        setTags([]);
      }
    } catch (error) {
      console.error("Error creating product:", error);
    }
  };

  return (
    <>
      <div className="createproduct">
        <Header />

        <section>
          {
            <div className="form_data">
              <div className="cross" onClick={backToDashBoard}>
                <div className="cross-inner">
                  <i class="bi bi-arrow-left"></i>
                </div>
                <h4>Back</h4>
              </div>
              <div className="form_heading">
                <h1>Create Product</h1>
              </div>

              <form>
                <div className="form_input">
                  <label htmlFor="title">Title</label>
                  <input
                    type="text"
                    onChange={onChangeInputHandler}
                    value={inputHandler.title}
                    id="title"
                    name="title"
                    placeholder="Title"
                  />
                </div>
                <div className="form_input">
                  <label htmlFor="shortDescription">Short description</label>
                  <textarea
                    style={{ width: "100%" }}
                    rows={3}
                    type="text"
                    onChange={onChangeInputHandler}
                    value={inputHandler.shortDescription}
                    id="shortDescription"
                    name="shortDescription"
                    placeholder=" A short Description ...."
                  />
                </div>

                <div className="form_input">
                  <label htmlFor="description">Description</label>
                  <textarea
                    style={{ width: "100%" }}
                    rows={10}
                    type="description"
                    onChange={onChangeInputHandler}
                    value={inputHandler.description}
                    id="description"
                    name="description"
                    placeholder="description... "
                  />
                </div>
                <div className="form_input">
                  <label htmlFor="categoryName">Category Name</label>
                  <select
                    className="categoryName"
                    id="categoryName"
                    name="categoryName"
                    value={inputHandler.categoryName}
                    onChange={handleCategoryChange}
                  >
                    <option value="">Select a category</option>
                    {categories.map((category, index) => (
                      <option key={index} value={category.name}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form_input">
                  <label htmlFor="subCategoryName">Sub Category Name</label>
                  <select
                    className="categoryName"
                    id="subCategoryName"
                    name="subCategoryName"
                    value={inputHandler.subCategoryName}
                    onChange={handleSubCategoryChange}
                  >
                    <option value="">Select a subcategory</option>
                    {subcategories.map((subcategory, index) => (
                      <option key={index} value={subcategory.name}>
                        {subcategory.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form_input">
                  <label for="productImage"> Image</label>

                  <div className="tags-input-container pt-2 cursor-pointer">
                    <input
                      type="file"
                      id="productImages"
                      multiple
                      onChange={(e) => setImage([...e.target.files])}
                      name="productImages"
                      accept="image/*"
                      required
                    />
                    {img.map((img, index) => (
                      <span className="tag-item p-2 cursor-pointer" key={index}>
                        <span className="text">{img}</span>
                        <span
                          className="close"
                          onClick={() => removeImg(index)}
                        >
                          &times;
                        </span>
                      </span>
                    ))}
                  </div>
                </div>
                {/* <div className="form_input">
                  <label for="productImage">Back Image</label>

                  <div className="tags-input-container pt-2 cursor-pointer">
                    <input
                      type="file"
                      id="backImage"
                      onKeyDown={(event) => addBackImg(event)}
                      onChange={(e) => setBackImage(e.target.files[0])}
                      name="backImage"
                      accept="image/*"
                      className="tags-input w-100 p-2"
                      required
                    />
                    {backImg.map((img, index) => (
                      <span className="tag-item p-2 cursor-pointer" key={index}>
                        <span className="text">{backImg}</span>
                        <span
                          className="close"
                          onClick={() => removeBackImg(index)}
                        >
                          &times;
                        </span>
                      </span>
                    ))}
                  </div>
                </div> */}
                <div className="tags">
                  <label>Colors</label>
                  <div className="tags-input-container pt-2 cursor-pointer">
                    <input
                      onKeyDown={(event) => addTags(event)}
                      type="text"
                      className="tags-input w-100 p-2"
                      placeholder="Please enter your tags here"
                    />
                    {tags.map((tag, index) => (
                      <span className="tag-item p-2 cursor-pointer" key={index}>
                        <span className="text">{tag}</span>
                        <span
                          className="close"
                          onClick={() => removeTags(index)}
                        >
                          &times;
                        </span>
                      </span>
                    ))}
                  </div>
                </div>
                <div className="btn" onClick={onSubmitHandler}>
                  Create Product
                </div>
              </form>
            </div>
          }
        </section>
        <Footer />
      </div>
    </>
  );
};

export default CreateProduct;
