import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../component/header/header";
import "./EditProduct.scss";
import Footer from "../../component/footer/footer";

const EditProduct = () => {
  const { id } = useParams();
  const [backImg, setBackImg] = useState([]);
  const [tags, setTags] = useState([]);
  const [frontImg, setFrontImg] = useState([]);
  const [image, setImage] = useState([]);

  const addTags = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      setTags([...tags, event.target.value]);
      event.target.value = "";
    }
  };
  const addFrontImg = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      setFrontImg([...frontImg, event.target.value]);
      event.target.value = "";
    }
  };
  const addBackImg = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      setBackImg([...backImg, event.target.value]);
      event.target.value = "";
    }
  };

  const removeTags = (index) => {
    setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
  };

  const [inputHandler, setInputHandler] = useState({
    title: " ",
    shortDescription: " ",
    description: " ",
    categoryName: "",
    subCategoryName: "",
  });
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  const onChangeInputHandler = (e) => {
    const { name, value } = e.target;
    setInputHandler(() => {
      return { ...inputHandler, [name]: value };
    });
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + `getProductById/${id}`)
      .then((res) => {
        console.log(res.data.data);

        setInputHandler({
          ...inputHandler,
          title: res.data.data.title,
          shortDescription: res.data.data.shortDescription,
          description: res.data.data.description,
          categoryName: res.data.data.categoryName,
          subCategoryName: res.data.data.subCategoryName,
          tags: res.data.data.tags || [],
          frontImage: res?.data?.data?.frontImagess
            ? res?.data?.data?.frontImagess[0]
            : res?.data?.data?.frontImage,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const history = useNavigate();

  const backToDashBoard = () => {
    history("/dashboard");
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "getCategory"
      );
      console.log(response.data[0]?.subcategories);
      setCategories(response.data[0]?.subcategories);
    } catch (error) {
      console.error("Failed to fetch categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleCategoryChange = (e) => {
    const selectedCategory = categories.find(
      (category) => category.name === e.target.value
    );
    setSubcategories(selectedCategory.subcategories);
    setInputHandler({ ...inputHandler, categoryName: e.target.value });
  };

  const handleSubCategoryChange = (e) => {
    setInputHandler({ ...inputHandler, subCategoryName: e.target.value });
  };

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token) {
    } else {
      history("/login");
    }
  }, []);

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    const {
      title,
      shortDescription,
      description,
      categoryName,
      subCategoryName,
    } = inputHandler;

    if (!title.trim()) {
      alert("Add Title");
      return;
    } else if (!shortDescription.trim()) {
      alert("Add short description");
      return;
    } else if (!description.trim()) {
      alert("Add description");
      return;
    }

    try {
      let uploadedImageUrls = [];

      // Only upload images if the user has provided new images
      if (Array.isArray(image) && image.length > 0) {
        const uploadPromises = image.map(async (img) => {
          if (img instanceof File || img instanceof Blob) {
            const formData = new FormData();
            formData.append("file", img);
            formData.append("upload_preset", "Aryan_Testing"); // Replace with your Cloudinary preset

            try {
              const { data } = await axios.post(
                "https://api.cloudinary.com/v1_1/dqvnizdtk/image/upload",
                formData
              );
              return data.secure_url || null;
            } catch (error) {
              console.error("Cloudinary Upload Error:", error);
              return null;
            }
          } else {
            console.error("Invalid image format:", img);
            return null;
          }
        });

        uploadedImageUrls = (await Promise.all(uploadPromises)).filter(Boolean);
      }

      console.log("Uploaded Images:", uploadedImageUrls);

      const newTags = tags.length > 0 ? tags : inputHandler.tags;

      // Prepare the update payload
      const updateData = {
        title,
        shortDescription,
        description,
        categoryName,
        subCategoryName,
        colors: newTags,
        // Include image URLs only if new images were uploaded
        ...(uploadedImageUrls.length > 0 && {
          frontImagess: uploadedImageUrls,
        }),
      };

      // Update product
      const { data } = await axios.put(
        `${process.env.REACT_APP_BASE_URL}updateProduct/${id}`,
        updateData
      );

      if (data.success) {
        alert("Product Updated Successfully");
        setInputHandler({
          ...inputHandler,
          title: "",
          shortDescription: "",
          description: "",
        });
      }
    } catch (error) {
      console.error("Error updating product:", error);
      alert("Something went wrong. Please try again.");
    }
  };

  console.log(inputHandler);

  return (
    <>
      <Header />
      <div className="editProduct">
        <section>
          {
            <div className="form_data">
              <div className="cross" onClick={backToDashBoard}>
                <div className="cross-inner">
                  <i class="bi bi-arrow-left"></i>
                </div>
                <h4>Back</h4>
              </div>
              <div className="form_heading">
                <h1>Edit Product</h1>
              </div>

              <form>
                <div className="form_input">
                  <label htmlFor="title">Title</label>
                  <input
                    type="text"
                    onChange={onChangeInputHandler}
                    value={inputHandler.title}
                    id="title"
                    name="title"
                    placeholder="Title"
                  />
                </div>
                <div className="form_input">
                  <label htmlFor="shortDescription">Short description</label>
                  <textarea
                    style={{ width: "100%" }}
                    rows={3}
                    type="text"
                    onChange={onChangeInputHandler}
                    value={inputHandler.shortDescription}
                    id="shortDescription"
                    name="shortDescription"
                    placeholder=" A short Description ...."
                  />
                </div>

                <div className="form_input">
                  <label htmlFor="description">Description</label>
                  <textarea
                    style={{ width: "100%" }}
                    rows={10}
                    type="description"
                    onChange={onChangeInputHandler}
                    value={inputHandler.description}
                    id="description"
                    name="description"
                    placeholder="description... "
                  />
                </div>
                <div className="form_input">
                  <label htmlFor="categoryName">Category Name</label>
                  <select
                    className="categoryName"
                    id="categoryName"
                    name="categoryName"
                    value={inputHandler.categoryName}
                    onChange={handleCategoryChange}
                  >
                    <option value="">uniform</option>
                    {/* {categories.map((category, index) => (
                      <option key={index} value={category.name}>
                        {category.name}
                      </option>
                    ))} */}
                  </select>
                </div>
                <div className="form_input">
                  <label htmlFor="subCategoryName">Sub Category Name</label>
                  <select
                    className="categoryName"
                    id="subCategoryName"
                    name="subCategoryName"
                    value={inputHandler.subCategoryName}
                    onChange={handleSubCategoryChange}
                  >
                    <option value="">Select a subcategory</option>
                    {categories.map((subcategory, index) => (
                      <option key={index} value={subcategory.name}>
                        {subcategory.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form_input">
                  <label for="productImage">Image</label>

                  <div className="tags-input-container pt-2 cursor-pointer">
                    <input
                      type="file"
                      id="productImages"
                      multiple
                      onChange={(e) => setImage([...e.target.files])}
                      name="productImages"
                      accept="image/*"
                      required
                    />
                  </div>
                </div>

                <div className="tags">
                  <label>Colors</label>
                  <div className="tags-input-container pt-2 cursor-pointer">
                    <input
                      onKeyDown={(event) => addTags(event)}
                      type="text"
                      className="tags-input w-100 p-2"
                      placeholder="Please enter your tags here"
                    />
                    {tags.map((tag, index) => (
                      <span className="tag-item p-2 cursor-pointer" key={index}>
                        <span className="text">{tag}</span>
                        <span
                          className="close"
                          onClick={() => removeTags(index)}
                        >
                          &times;
                        </span>
                      </span>
                    ))}
                  </div>
                </div>
                <div className="btn" onClick={onSubmitHandler}>
                  Update Product
                </div>
              </form>
            </div>
          }
        </section>
      </div>
      <Footer />
    </>
  );
};

export default EditProduct;
