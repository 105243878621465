import React, { useState, useEffect } from "react";
// import "../CreateBlog/createBlog";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Header from "../../component/header/header";
import Footer from "../../component/footer/footer";
import "./editBlog.scss";

const EditBlog = () => {
  const history = useNavigate();
  const [image, setImage] = useState([]);
  const { id } = useParams();
  const [inputHandler, setInputHandler] = useState({
    id: id,
    title: "",
    content: "",
    keyword: "",
  });

  const getOneBlog = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_BASE_URL + "blogsectionSingleBlog/" + id
      );
      // console.log(data);
      setInputHandler({
        title: data.data.title,
        content: data.data.content,
        keyword: data.data.keyword,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token) {
    } else {
      history("/login");
    }
  }, []);

  useEffect(() => {
    getOneBlog();
  }, []);

  useEffect(() => {
    axios
      .put(process.env.REACT_APP_BASE_URL + "blogsection/updateBlog/" + id)
      .then((res) => {
        // console.log(res.data);
        setInputHandler({
          ...inputHandler,
          title: res.data.updatedBlog.title,
          content: res.data.updatedBlog.content,
          keyword: res.data.updatedBlog.keyword,
          image: res.data.updatedBlog.image,
          EditBlog,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const backToDashboard = () => {
    history("/dashboard");
  };

  const onChangeInputHandler = (e) => {
    const { name, value } = e.target;
    setInputHandler(() => {
      return { ...inputHandler, [name]: value };
    });
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    try {
      let uploadedImageUrls = [];

      // Check if images are provided and process them
      if (Array.isArray(image) && image.length > 0) {
        const uploadPromises = image.map(async (img) => {
          if (img instanceof File || img instanceof Blob) {
            const formData = new FormData();
            formData.append("file", img);
            formData.append("upload_preset", "Aryan_Testing"); // Replace with your Cloudinary preset

            try {
              const { data } = await axios.post(
                "https://api.cloudinary.com/v1_1/dqvnizdtk/image/upload",
                formData
              );
              return data.secure_url || null;
            } catch (error) {
              console.error("Cloudinary Upload Error:", error);
              return null;
            }
          } else {
            console.error("Invalid image format:", img);
            return null;
          }
        });

        uploadedImageUrls = (await Promise.all(uploadPromises)).filter(Boolean);
      }

      // // If no images uploaded successfully, alert and return
      // if (uploadedImageUrls.length === 0) {
      //   alert("Image upload failed");
      //   return;
      // }

      console.log("Uploaded Images:", uploadedImageUrls);

      // Prepare the data for the update request
      const updateData = {
        title: inputHandler.title,
        content: inputHandler.content,
        keyword: inputHandler.keyword,
        image: uploadedImageUrls[0], // Store the uploaded image URLs
      };

      // Update the blog
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}blogsection/updateBlog/${id}`,
        updateData
      );

      history("/dashboard");
    } catch (err) {
      console.log("Error submitting blog:", err);
    }
  };

  return (
    <>
      <div className="editBlog">
        <Header />
        <section>
          <div className="form_data">
            <div
              className="cross d-flex align-item-center flex-row"
              onClick={backToDashboard}
            >
              <div className="cross-inner">
                <i class="bi bi-arrow-left"></i>
              </div>
              <h4>Back</h4>
            </div>
            <div className="form_heading">
              <h1>Update Blog</h1>
            </div>

            <form>
              <div className="form_input">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  onChange={onChangeInputHandler}
                  id="title"
                  name="title"
                  value={inputHandler.title}
                  placeholder="Title"
                />
              </div>
              <div className="form_input">
                <label htmlFor="title">Content</label>
                <textarea
                  style={{ width: "100%" }}
                  rows={10}
                  type="text"
                  onChange={onChangeInputHandler}
                  value={inputHandler.content}
                  id="content"
                  name="content"
                  placeholder="Content ...."
                />
              </div>
              {/* <div className="form_input">
              <label htmlFor="readingTime"> Estimate Reading Time</label>
              <input
                type="readingTime"
                onChange={onChangeInputHandler}
                value={inputHandler.readingTime}
                id="readingTime"
                name="readingTime"
                placeholder="readingTime"
              />
            </div> */}
              <div className="form_input">
                <label for="productImage">Resource Image</label>
                <input
                  type="file"
                  id="productImages"
                  multiple
                  onChange={(e) => setImage([...e.target.files])}
                  name="productImages"
                  accept="image/*"
                />
              </div>
              <button className="btn" onClick={onSubmitHandler}>
                Update Blog
              </button>
            </form>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default EditBlog;
