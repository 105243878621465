import React, { useEffect, useState } from "react";
import "./singleresource.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../component/header/header";
import axios from "axios";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import Footer from "../../component/footer/footer";
import DOMPurify from "dompurify";

function SingleBlog() {
  const location = useLocation(); // Gets the full URL including query params

  const navigate = useNavigate();
  // Parse query parameters
  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get("name")?.replace(/-/g, " ");

  const [blog, setBlog] = useState(null);
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_BASE_URL + `blogsection/blogs`
        );
        if (response.data.success) {
          setBlogs(response.data.data);
        } else {
          console.log(response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchBlogs();
  }, []);
  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_BASE_URL + `blogsection/blogwithName/${name}`
        );
        setBlog(response.data.data);
      } catch (error) {
        console.error("Error fetching blog:", error);
      }
    };

    fetchBlog();
  }, [name]);

  useEffect(() => {
    if (blogs.length > 0) {
      setIsLoading(false);
    }
  }, [blogs]);
  if (!blog) {
    return (
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="singleResource">
        <div className="singleResource-hero-img-section">
          <Header />
          <div className="singleResource-main-hero-content-section">
            <div className="singleResource-image">
              <img src={blog.image} alt="jbhjbkjbk" />
            </div>
          </div>
        </div>
        <div className="singleResource-content">
          <div className="heading">
            <h1>{blog.title}</h1>
          </div>
          <div className="keywordsAndShare ">
            <div className="keyword-outer">
              {blog.tags.map((tag, index) => (
                <div className="keyword-inner" key={index}>
                  <p>{tag}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="description">
            <p
              style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(blog.content),
              }}
            ></p>
          </div>
        </div>

        <div className="latest-posts">
          <div className="container-main">
            <div className="heading">
              <h5>Related Posts</h5>
            </div>
            {isLoading == true ? (
              <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="sr-only"></span>
                </div>
              </div>
            ) : (
              <div className="posts">
                <OwlCarousel
                  className="owl-theme"
                  loop
                  margin={0}
                  nav
                  responsive={{
                    0: {
                      items: 1,
                    },
                    600: {
                      items: 2,
                    },
                    1000: {
                      items: 3,
                    },
                  }}
                >
                  {blogs &&
                    blogs.map((blog) => (
                      <div className="column-gapping" key={blog._id}>
                        <div
                          className="blog-card"
                          onClick={(event) => {
                            if (
                              event.ctrlKey ||
                              event.metaKey ||
                              event.button === 1
                            ) {
                              const newTabUrl = `SingleBlog?name=${blog.title.replace(
                                /\s+/g,
                                "-"
                              )}`;
                              window.open(newTabUrl, "_blank");
                            } else {
                              window.scrollTo(0, 0);
                              navigate(
                                `SingleBlog?name=${blog.title.replace(
                                  /\s+/g,
                                  "-"
                                )}`
                              );
                            }
                          }}
                        >
                          <div className="img">
                            <img src={blog.image} alt={blog.title} />
                          </div>
                          <div className="info">
                            <h5>
                              {blog.title.length > 20
                                ? blog.title.substring(0, 20) + "..."
                                : blog.title}
                            </h5>
                            <p>{blog.content.substring(0, 35)}...</p>
                            <div className="readmore">
                              <Link>Read More</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </OwlCarousel>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SingleBlog;
