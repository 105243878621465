import React, { useState, useEffect } from "react";
import "./customisedTshirt.scss";
import axios from "axios";
import { useParams } from "react-router-dom";
import Header from "../../component/header/header";
import Footer from "../../component/footer/footer";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";

const CustomisedTshirt = () => {
  const [radioValue, setRadioValue] = useState("");
  const [inputHandler, setInputHandler] = useState({
    name: "",
    number: "",
    companyName: "",
    industry: "",
    customShirt: "",
    designTheme: "",
    designColor: "",
    designLocation: "",
    envision: "",
    shirtStyle: "",
    shirtMaterial: "",
    shirtCount: "",
  });
  const [image, setImage] = useState();

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  const onChangeInputHandler = (event) => {
    setInputHandler({
      ...inputHandler,
      [event.target.name]: event.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const {
      name,
      number,
      companyName,
      industry,
      customShirt,
      designTheme,
      designColor,
      designLocation,
      envision,
      shirtStyle,
      shirtMaterial,
      shirtCount,
    } = inputHandler;

    const formData = new FormData();
    formData.append("image", image);

    const imageUrl = await axios.post(
      process.env.REACT_APP_BASE_URL + "upload",
      formData
    );
    if (imageUrl) {
      console.log(imageUrl.data.url);
      if (imageUrl?.data.success) {
        const { data } = await axios.post(
          process.env.REACT_APP_BASE_URL + "enquiryRoute/createEnquiry",
          {
            name: inputHandler.name,
            number: inputHandler.number,
            image: imageUrl?.data.url,
            companyName: inputHandler.companyName,
            industry: inputHandler.industry,
            customShirt: inputHandler.customShirt,
            designTheme: inputHandler.designTheme,
            designColor: inputHandler.designColor,
            designLocation: inputHandler.designLocation,
            envision: inputHandler.envision,
            shirtStyle: inputHandler.shirtStyle,
            shirtMaterial: inputHandler.shirtMaterial,
            shirtCount: inputHandler.shirtCount,
          }
        );
        if (data.success) {
          toast.success("Response Send Successfully", {
            position: "bottom-right",
            autoClose: 8000,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
          });
          setInputHandler({
            ...inputHandler,
            name: "",
            number: "",
            companyName: "",
            industry: "",
            customShirt: "",
            designTheme: "",
            designColor: "",
            designLocation: "",
            envision: "",
            shirtStyle: "",
            shirtMaterial: "",
            shirtCount: "",
          });
        }
      }
    }
  };

  return (
    <div className="customisedTshirt">
      <Helmet>
        <title>
          Print and Design Good Quality T-Shirt with Customised Logo
        </title>
        <meta
          name="description"
          content="Design your custom T-Shirt that's just as unique as you are. Fill the form at the website and get good quality t-shirts and print your favourite logo."
        />
        <meta
          name="keywords"
          content="design your t shirt, design t shirt with logo, design your own t shirt good quality, create your own tshirts, make t shirt design, print your own t shirt design"
        />
      </Helmet>
      <div className="contact-hero-img-section">
        <Header />
        <div className="contact-main-hero-content-section">
          <h1 className="contact-content">Design Your Customised T-shirt</h1>
        </div>
      </div>
      <div className="wrapper">
        <div className="divTshirt">
          <p className="heading-tshirt">
            Tailor your custom T-Shirt that's just as unique as you are. Fill
            the form down below
          </p>
        </div>

        <div className="Container-1">
          <div className="row">
            <div className=" Personal col-md-12">
              <h3>Personal And Company Details</h3>
            </div>
            <div className="name col-md-6 p-2">
              {/* <h6>
                Name <span style={{ color: "red" }}>*</span>
              </h6> */}
              <input
                type="text"
                name="name"
                className="w-100"
                value={inputHandler.name}
                onChange={onChangeInputHandler}
                placeholder="Name"
              />
            </div>
            <div className="name col-md-6 p-2">
              {/* <h6>
                Contact Number <span style={{ color: "red" }}>*</span>
              </h6> */}
              <input
                type="text"
                name="number"
                value={inputHandler.number}
                onChange={onChangeInputHandler}
                className="w-100"
                placeholder="Contact Number"
              />
            </div>
            <div className="name col-md-6 p-2">
              {/* <h6>
                Company Name
              </h6> */}
              <input
                type="text"
                name="companyName"
                value={inputHandler.companyName}
                className="w-100"
                onChange={onChangeInputHandler}
                placeholder="Company Name"
              />
            </div>
            <div className="name col-md-6 p-2 ">
              {/* <h6>
                Industry 
              </h6> */}
              <input
                width="100%"
                type="text"
                name="industry"
                className="w-100"
                value={inputHandler.industry}
                onChange={onChangeInputHandler}
                placeholder="Industry"
              />
            </div>
            <div className="name col-md-12">
              <h6>Company Logo</h6>
              <input
                type="file"
                id="image"
                name="image"
                className="w-100"
                onChange={(e) => setImage(e.target.files[0])}
                required
              />
            </div>
          </div>
          <hr />
          <div className="row col-md-12 purpose">
            <h3 className="purpose-2">Purpose of T-Shirts</h3>
            <div className="t-shirt">
              <label>
                <h6>
                  What is the primary purpose of these custom T-shirts? (e.g.,
                  team building, corporate events, promotional giveaways, etc.)
                </h6>
              </label>
              <input
                type="text"
                name="customShirt"
                className="w-100"
                value={inputHandler.customShirt}
                onChange={onChangeInputHandler}
                placeholder="e.g.team building..."
              />
            </div>
          </div>

          <hr />

          <div className="row theme">
            <h3>Design Theme And Preferences</h3>
            <div className="design-theme">
              <label>
                <h6>
                  Are there any design elements (e.g., text, logos, slogans,
                  icons) that should be included? If yes, please provide details
                  or attach the same.
                </h6>
              </label>
              <div className="design">
                <input
                  type="radio"
                  value="yes"
                  checked={radioValue === "yes"}
                  onChange={handleRadioChange}
                />
                <label>Yes</label>
              </div>
              <div className="design">
                <input
                  type="radio"
                  value="no"
                  checked={radioValue === "no"}
                  onChange={handleRadioChange}
                />
                <label>No</label>
              </div>
              {radioValue === "yes" && (
                <input
                  type="text"
                  name="designTheme"
                  className="w-100 yes-input"
                  value={inputHandler.designTheme}
                  onChange={onChangeInputHandler}
                  placeholder="e.g. text, logos..."
                />
              )}
            </div>
            <div className="t-shirt">
              <label>
                <h6>
                  Can you provide examples of T-shirt designs that you like or
                  dislike? (Attach links)
                </h6>
              </label>
              <input
                type="text"
                name="designTShirt"
                className="w-100"
                value={inputHandler.designTShirt}
                onChange={onChangeInputHandler}
                placeholder="Attach Links..."
              />
            </div>
            <div className="t-shirt">
              <label>
                <h6>What are your preferred colors for the T-shirt design?</h6>
              </label>
              <input
                type="text"
                name="designColor"
                className="w-100"
                value={inputHandler.designColor}
                onChange={onChangeInputHandler}
                placeholder="Colors..."
              />
            </div>
            <div className="t-shirt">
              <label>
                <h6>
                  Are there any specific locations on the T-shirt where you
                  would like the logo to appear?
                </h6>
              </label>
              <input
                type="text"
                name="designLocation"
                className="w-100"
                value={inputHandler.designLocation}
                onChange={onChangeInputHandler}
                placeholder="Locations..."
              />
            </div>
            <div className="t-shirt">
              <label>
                <h6>
                  How would you describe the overall style or vibe you envision
                  for the custom T-shirts? (e.g., modern, minimalist, bold,
                  playful)
                </h6>
              </label>
              <input
                type="text"
                name="envision"
                className="w-100"
                value={inputHandler.envision}
                onChange={onChangeInputHandler}
                placeholder="e.g.moderns,bold..."
              />
            </div>
          </div>

          <hr />
          <div className="row w-100 style-preference">
            <h3 className="col-md-12 tg">Style Preferences</h3>
            <div className="t-shirt">
              <label>
                <h6>
                  What style of T-shirt do you prefer? (e.g., crew neck, V-neck,
                  polo, long sleeve, short sleeve)
                </h6>
              </label>
              <input
                type="text"
                name="shirtStyle"
                className="w-100"
                value={inputHandler.shirtStyle}
                onChange={onChangeInputHandler}
                placeholder="e.g.crew neck , short sleeve..."
              />
            </div>
          </div>

          <hr />

          <div className="submit-button">
            <button onClick={submitHandler} className="button">
              Submit
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </div>
  );
};

export default CustomisedTshirt;
