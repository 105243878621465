import React from "react";
import SchoolChart from "./../../assets/uniform-size-chart.png";
import "./sizeguide.scss";
import hosiery1 from "./../../assets/hosiery1.png";
import hosiery2 from "./../../assets/hosiery2.png";
import hosiery3 from "./../../assets/hosiery3.png";
import hosiery4 from "./../../assets/hosiery4.png";
import industries from "../../assets/industrialshirt.jpg";

const SizeGuide = () => {
  return (
    <>
      <div className="size-guide">
        <div className="button">
          <button
            type="button"
            class="btn"
            data-bs-toggle="modal"
            data-bs-target="#sizecategory"
          >
            Size Guide
          </button>
        </div>
        <div
          class="modal fade size-category"
          id="sizecategory"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
        >
          <div class="modal-dialog modal-dialog-centered modal-xl ">
            <div class="modal-content">
              <div class="modal-header">
                <h2 class="modal-title fs-5" id="exampleModalToggleLabel">
                  Size Guide
                </h2>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <button
                  class="btn btn-primary card-btn"
                  data-bs-target="#sizeguide"
                  data-bs-toggle="modal"
                >
                  School Uniform Size Guide
                </button>
                <button
                  class="btn btn-primary card-btn"
                  data-bs-target="#sizeguideIndustrial"
                  data-bs-toggle="modal"
                >
                  Industrial Uniform Size Guide
                </button>
                <button
                  class="btn btn-primary card-btn"
                  data-bs-target="#sizeguideMens"
                  data-bs-toggle="modal"
                >
                  Mens T-Shirt Size Guide
                </button>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade modal-school-uniform"
          id="sizeguide"
          tabindex="-1"
          aria-labelledby="size-guide"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl">
            <div class="modal-content w-100">
              <div class="modal-header">
                <h2 class="modal-title fs-5" id="size-gide">
                  Uniforms Size Guide
                </h2>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <img src={SchoolChart}></img>
                <div className="tabs">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#girls-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="girls-tab-pane"
                        aria-selected="true"
                      >
                        Girls
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="boys-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#boys-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="boys-tab-pane"
                        aria-selected="false"
                      >
                        Boys
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="hosiery-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#hosiery-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="hosiery-tab-pane"
                        aria-selected="false"
                      >
                        Hosiery
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="accessory-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#accessory-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="accessory-tab-pane"
                        aria-selected="false"
                      >
                        Accessory
                      </button>
                    </li>
                  </ul>
                  <div class="tab-content" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="girls-tab-pane"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                      tabindex="0"
                    >
                      <div className="tables">
                        <div className="table1 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Girls - Shirt Full Sleeves
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>20</td>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                  <td>44</td>
                                </tr>
                                <tr>
                                  <th>Chest</th>
                                  <td>20</td>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                  <td>44</td>
                                </tr>
                                <tr>
                                  <th>Length</th>
                                  <td>18</td>
                                  <td>19</td>
                                  <td>20</td>
                                  <td>21</td>
                                  <td>22</td>
                                  <td>23.5</td>
                                  <td>24.5</td>
                                  <td>25.5</td>
                                  <td>26.5</td>
                                  <td>27.5</td>
                                  <td>28.5</td>
                                  <td>29.5</td>
                                  <td>30.5</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="table2 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Girls - Shirt Half Sleeves
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>20</td>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                  <td>44</td>
                                </tr>
                                <tr>
                                  <th>Chest</th>
                                  <td>20</td>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                  <td>44</td>
                                </tr>
                                <tr>
                                  <th>Length</th>
                                  <td>18</td>
                                  <td>19</td>
                                  <td>20</td>
                                  <td>21</td>
                                  <td>22</td>
                                  <td>23.5</td>
                                  <td>24.5</td>
                                  <td>25.5</td>
                                  <td>26.5</td>
                                  <td>27.5</td>
                                  <td>28.5</td>
                                  <td>29.5</td>
                                  <td>30.5</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="table3 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Girls - Full Pants
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                  <td>44</td>
                                  <td>46</td>
                                </tr>
                                <tr>
                                  <th>Length</th>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                  <td>44</td>
                                  <td>46</td>
                                </tr>

                                <tr>
                                  <th>
                                    Waist Relaxed <br />
                                    <span>(Back Elastic)</span>
                                  </th>
                                  <td>21</td>
                                  <td>22</td>
                                  <td>23</td>
                                  <td>24</td>
                                  <td>25</td>
                                  <td>26</td>
                                  <td>27</td>
                                  <td>28</td>
                                  <td>29</td>
                                  <td>30</td>
                                  <td>32</td>
                                </tr>
                                <tr>
                                  <th>
                                    Waist Extended <br />
                                    <span>(Back Elastic)</span>
                                  </th>
                                  <td>25</td>
                                  <td>26</td>
                                  <td>27</td>
                                  <td>28</td>
                                  <td>29</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>35</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="table4 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Girls - salwar
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                  <td>44</td>
                                </tr>
                                <tr>
                                  <th>Length</th>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                  <td>44</td>
                                </tr>

                                <tr>
                                  <th>
                                    Waist Relaxed <br />
                                    <span>(Back Elastic)</span>
                                  </th>
                                  <td>20</td>
                                  <td>21</td>
                                  <td>22</td>
                                  <td>23</td>
                                  <td>24</td>
                                  <td>25</td>
                                  <td>26</td>
                                  <td>27</td>
                                  <td>28</td>
                                  <td>29</td>
                                  <td>30</td>
                                </tr>
                                <tr>
                                  <th>
                                    Waist Extended <br />
                                    <span>(Back Elastic)</span>
                                  </th>
                                  <td>24</td>
                                  <td>25</td>
                                  <td>26</td>
                                  <td>27</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>35</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="table5 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Girls - Kurta
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                </tr>
                                <tr>
                                  <th>Chest</th>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                </tr>

                                <tr>
                                  <th>Length</th>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="table6 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="24">
                                    Girls - Divider
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>16*22</td>
                                  <td>18*24</td>
                                  <td>18*26</td>
                                  <td>18*28</td>
                                  <td>20*26</td>
                                  <td>20*28</td>
                                  <td>20*30</td>
                                  <td>20*32</td>
                                  <td>22*26</td>
                                  <td>22*28</td>
                                  <td>22*30</td>
                                  <td>22*32</td>
                                  <td>24*28</td>
                                  <td>24*30</td>
                                  <td>24*32</td>
                                  <td>24*34</td>
                                  <td>26*30</td>
                                  <td>26*32</td>
                                  <td>26*34</td>
                                  <td>26*36</td>
                                  <td>26*38</td>
                                </tr>
                                <tr>
                                  <th>Length</th>
                                  <td>16</td>
                                  <td>18</td>
                                  <td>18</td>
                                  <td>18</td>
                                  <td>20</td>
                                  <td>20</td>
                                  <td>20</td>
                                  <td>20</td>
                                  <td>22</td>
                                  <td>22</td>
                                  <td>22</td>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>24</td>
                                  <td>24</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>26</td>
                                  <td>26</td>
                                  <td>26</td>
                                  <td>26</td>
                                </tr>
                                <tr>
                                  <th>Waist</th>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="table7 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Girls - T Shirts
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                </tr>
                                <tr>
                                  <th>Chest</th>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                </tr>
                                <tr>
                                  <th>Length</th>
                                  <td>18</td>
                                  <td>20</td>
                                  <td>21</td>
                                  <td>22.5</td>
                                  <td>24</td>
                                  <td>25</td>
                                  <td>26.5</td>
                                  <td>27.5</td>
                                  <td>28</td>
                                  <td>29.5</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="table8 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Girls - Top
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>20</td>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                  <td>44</td>
                                </tr>
                                <tr>
                                  <th>Chest</th>
                                  <td>20</td>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                  <td>44</td>
                                </tr>
                                <tr>
                                  <th>Length</th>

                                  <td>18</td>
                                  <td>19</td>
                                  <td>20</td>
                                  <td>21</td>
                                  <td>22</td>
                                  <td>23.5</td>
                                  <td>24.5</td>
                                  <td>25.5</td>
                                  <td>26.5</td>
                                  <td>27.5</td>
                                  <td>28.5</td>
                                </tr>
                                <tr>
                                  <th>Length</th>
                                  <td>20</td>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="table9 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Girls - P P Divider
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>

                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                </tr>
                                <tr>
                                  <th>Chest</th>

                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>

                                  <td>40</td>
                                  <td>42</td>
                                  <td>44</td>
                                  <td>46</td>
                                </tr>

                                <tr>
                                  <th>
                                    Waist Relaxed <br />
                                    <span>(Back Elastic)</span>
                                  </th>
                                  <td>21</td>
                                  <td>22</td>
                                  <td>23</td>
                                  <td>24</td>
                                  <td>25</td>
                                  <td>26</td>
                                  <td>27</td>
                                  <td>28</td>
                                  <td>29</td>
                                  <td>30</td>
                                  <td>32</td>
                                </tr>
                                <tr>
                                  <th>
                                    Waist Extended <br />
                                    <span>(Back Elastic)</span>
                                  </th>
                                  <td>25</td>
                                  <td>26</td>
                                  <td>27</td>
                                  <td>28</td>
                                  <td>29</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>35</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="table4 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Girls - Waist Coat
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>

                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>

                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>

                                  <td>40</td>
                                  <td>42</td>
                                  <td>44</td>
                                </tr>
                                <tr>
                                  <th>Length</th>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>

                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>

                                  <td>40</td>
                                  <td>42</td>
                                  <td>44</td>
                                </tr>

                                <tr>
                                  <th>
                                    Waist Relaxed <br />
                                    <span>(Back Elastic)</span>
                                  </th>
                                  <td>20</td>
                                  <td>21</td>
                                  <td>22</td>
                                  <td>23</td>
                                  <td>24</td>
                                  <td>25</td>
                                  <td>26</td>
                                  <td>27</td>
                                  <td>28</td>
                                  <td>29</td>
                                  <td>30</td>
                                </tr>
                                <tr>
                                  <th>
                                    Waist Extended <br />
                                    <span>(Back Elastic)</span>
                                  </th>
                                  <td>24</td>
                                  <td>25</td>
                                  <td>26</td>
                                  <td>27</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>35</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="table5 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Girls - Kurta
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                </tr>
                                <tr>
                                  <th>Chest</th>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                </tr>

                                <tr>
                                  <th>Length</th>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="table6 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="24">
                                    Girls - Divider
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>16*22</td>
                                  <td>18*24</td>
                                  <td>18*26</td>
                                  <td>18*28</td>
                                  <td>20*26</td>
                                  <td>20*28</td>
                                  <td>20*30</td>
                                  <td>20*32</td>
                                  <td>22*26</td>
                                  <td>22*28</td>
                                  <td>22*30</td>
                                  <td>22*32</td>
                                  <td>24*28</td>
                                  <td>24*30</td>
                                  <td>24*32</td>
                                  <td>24*34</td>
                                  <td>26*30</td>
                                  <td>26*32</td>
                                  <td>26*34</td>
                                  <td>26*36</td>
                                  <td>26*38</td>
                                </tr>
                                <tr>
                                  <th>Length</th>
                                  <td>16</td>
                                  <td>18</td>
                                  <td>18</td>
                                  <td>18</td>
                                  <td>20</td>
                                  <td>20</td>
                                  <td>20</td>
                                  <td>20</td>
                                  <td>22</td>
                                  <td>22</td>
                                  <td>22</td>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>24</td>
                                  <td>24</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>26</td>
                                  <td>26</td>
                                  <td>26</td>
                                  <td>26</td>
                                </tr>
                                <tr>
                                  <th>Waist</th>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="table7 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Girls - T Shirts
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                </tr>
                                <tr>
                                  <th>Chest</th>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                </tr>
                                <tr>
                                  <th>Length</th>
                                  <td>18</td>
                                  <td>20</td>
                                  <td>21</td>
                                  <td>22.5</td>
                                  <td>24</td>
                                  <td>25</td>
                                  <td>26.5</td>
                                  <td>27.5</td>
                                  <td>28</td>
                                  <td>29.5</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="table8 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Girls - Top
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>20</td>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                </tr>
                                <tr>
                                  <th>Chest</th>
                                  <td>18</td>
                                  <td>19</td>
                                  <td>20</td>
                                  <td>21</td>
                                  <td>22</td>
                                  <td>23.5</td>
                                  <td>24.5</td>
                                  <td>25.5</td>
                                  <td>26.5</td>
                                  <td>27.5</td>
                                  <td>28.5</td>
                                </tr>
                                <tr>
                                  <th>Length</th>
                                  <td>20</td>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="table9 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Girls - P P Divider
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                </tr>
                                <tr>
                                  <th>Chest</th>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                </tr>
                                <tr>
                                  <th>Length</th>
                                  <td>11</td>
                                  <td>12</td>
                                  <td>13</td>
                                  <td>14</td>
                                  <td>15</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="table10 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Girls - Waist Coat
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                </tr>
                                <tr>
                                  <th>Chest</th>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                </tr>
                                <tr>
                                  <th>Length</th>
                                  <td>22</td>
                                  <td>23</td>
                                  <td>24</td>
                                  <td>24</td>
                                  <td>25</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="table11 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Girls - Frock
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                </tr>

                                <tr>
                                  <th>Chest</th>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                </tr>
                                <tr>
                                  <th>Length</th>
                                  <td>23</td>
                                  <td>25</td>
                                  <td>27</td>
                                  <td>29</td>
                                  <td>31</td>
                                  <td>33</td>
                                  <td>35</td>
                                  <td>37</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="table12 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Girls - Capree
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>15</td>
                                  <td>16</td>
                                  <td>17</td>
                                  <td>18</td>
                                  <td>19</td>
                                  <td>20</td>
                                </tr>
                                <tr>
                                  <th>Length</th>
                                  <td>15</td>
                                  <td>16</td>
                                  <td>17</td>
                                  <td>18</td>
                                  <td>19</td>
                                  <td>20</td>
                                </tr>
                                <tr>
                                  <th>Waist</th>
                                  <td>21</td>
                                  <td>22</td>
                                  <td>23</td>
                                  <td>24</td>
                                  <td>25</td>
                                  <td>26</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="table13 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Girls - Dungree Skirt
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>12</td>
                                  <td>13</td>
                                  <td>14</td>
                                  <td>15</td>
                                  <td>16</td>
                                  <td>17</td>
                                  <td>18</td>
                                </tr>
                                <tr>
                                  <th>Length</th>
                                  <td>12</td>
                                  <td>13</td>
                                  <td>14</td>
                                  <td>15</td>
                                  <td>16</td>
                                  <td>17</td>
                                  <td>18</td>
                                </tr>
                                <tr>
                                  <th>Waist</th>
                                  <td>19</td>
                                  <td>20</td>
                                  <td>21</td>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>25</td>
                                  <td>26</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="boys-tab-pane"
                      role="tabpanel"
                      aria-labelledby="boys-tab"
                      tabindex="0"
                    >
                      <div className="tables">
                        <div className="table1 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Boys - Shirt Full Sleeves
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>20</td>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                </tr>
                                <tr>
                                  <th>Chest</th>
                                  <td>20</td>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                </tr>
                                <tr>
                                  <th>Length</th>
                                  <td>18</td>
                                  <td>19</td>
                                  <td>20</td>
                                  <td>21</td>
                                  <td>22</td>
                                  <td>23.5</td>
                                  <td>24.5</td>
                                  <td>25.5</td>
                                  <td>26.5</td>
                                  <td>27.5</td>
                                  <td>28.5</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="table2 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Boys - Shirt Half Sleeves
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>20</td>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                </tr>
                                <tr>
                                  <th>Chest</th>
                                  <td>20</td>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                </tr>
                                <tr>
                                  <th>Length</th>
                                  <td>18</td>
                                  <td>19</td>
                                  <td>20</td>
                                  <td>21</td>
                                  <td>22</td>
                                  <td>23.5</td>
                                  <td>24.5</td>
                                  <td>25.5</td>
                                  <td>26.5</td>
                                  <td>27.5</td>
                                  <td>28.5</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="table3 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Boys - Full Pants
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                  <td>44</td>
                                  <td>46</td>
                                </tr>
                                <tr>
                                  <th>Length</th>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                  <td>44</td>
                                  <td>46</td>
                                </tr>

                                <tr>
                                  <th>
                                    Waist Relaxed <br />
                                    <span>(Back Elastic)</span>
                                  </th>
                                  <td>21</td>
                                  <td>22</td>
                                  <td>23</td>
                                  <td>24</td>
                                  <td>25</td>
                                  <td>26</td>
                                  <td>27</td>
                                  <td>28</td>
                                  <td>29</td>
                                  <td>30</td>
                                  <td>32</td>
                                </tr>
                                <tr>
                                  <th>
                                    Waist Extended <br />
                                    <span>(Back Elastic)</span>
                                  </th>
                                  <td>25</td>
                                  <td>27</td>
                                  <td>28</td>
                                  <td>29</td>
                                  <td>30</td>
                                  <td>31</td>
                                  <td>32</td>
                                  <td>33</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>40</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="table4 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Boys - Half Pants
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                  <td>44</td>
                                </tr>
                                <tr>
                                  <th>Length</th>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                  <td>44</td>
                                </tr>

                                <tr>
                                  <th>
                                    Waist Relaxed <br />
                                    <span>(Back Elastic)</span>
                                  </th>
                                  <td>20</td>
                                  <td>21</td>
                                  <td>22</td>
                                  <td>23</td>
                                  <td>24</td>
                                  <td>25</td>
                                  <td>26</td>
                                  <td>27</td>
                                  <td>28</td>
                                  <td>29</td>
                                  <td>30</td>
                                </tr>
                                <tr>
                                  <th>
                                    Waist Extended <br />
                                    <span>(Back Elastic)</span>
                                  </th>
                                  <td>24</td>
                                  <td>25</td>
                                  <td>26</td>
                                  <td>27</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>35</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="table5 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Boys - Pajama
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                  <td>44</td>
                                </tr>
                                <tr>
                                  <th>Length</th>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                  <td>44</td>
                                </tr>

                                <tr>
                                  <th>
                                    Waist Relaxed <br />
                                    <span>(Back Elastic)</span>
                                  </th>
                                  <td>20</td>
                                  <td>21</td>
                                  <td>22</td>
                                  <td>23</td>
                                  <td>24</td>
                                  <td>25</td>
                                  <td>26</td>
                                  <td>27</td>
                                  <td>28</td>
                                  <td>29</td>
                                  <td>30</td>
                                </tr>
                                <tr>
                                  <th>
                                    Waist Extended <br />
                                    <span>(Back Elastic)</span>
                                  </th>
                                  <td>24</td>
                                  <td>25</td>
                                  <td>26</td>
                                  <td>27</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>35</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="table6 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Boys - Kurta
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                </tr>
                                <tr>
                                  <th>Chest</th>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                </tr>

                                <tr>
                                  <th>Length</th>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="table7 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Boys - P P Half Pants
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                  <td>44</td>
                                </tr>
                                <tr>
                                  <th>Length</th>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                  <td>44</td>
                                </tr>

                                <tr>
                                  <th>
                                    Waist Relaxed <br />
                                    <span>(Back Elastic)</span>
                                  </th>
                                  <td>20</td>
                                  <td>21</td>
                                  <td>22</td>
                                  <td>23</td>
                                  <td>24</td>
                                  <td>25</td>
                                  <td>26</td>
                                  <td>27</td>
                                  <td>28</td>
                                  <td>29</td>
                                  <td>30</td>
                                </tr>
                                <tr>
                                  <th>
                                    Waist Extended <br />
                                    <span>(Back Elastic)</span>
                                  </th>
                                  <td>24</td>
                                  <td>25</td>
                                  <td>26</td>
                                  <td>27</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>35</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="table8 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Boys- T Shirts
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                </tr>
                                <tr>
                                  <th>Chest</th>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                </tr>
                                <tr>
                                  <th>Length</th>
                                  <td>18</td>
                                  <td>20</td>
                                  <td>21</td>
                                  <td>22.5</td>
                                  <td>24</td>
                                  <td>25</td>
                                  <td>26.5</td>
                                  <td>27.5</td>
                                  <td>28</td>
                                  <td>29.5</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="table9 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Boys - Capree
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>20</td>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                </tr>
                                <tr>
                                  <th>Chest</th>
                                  <td>18</td>
                                  <td>19</td>
                                  <td>20</td>
                                  <td>21</td>
                                  <td>22</td>
                                  <td>23.5</td>
                                  <td>24.5</td>
                                  <td>25.5</td>
                                  <td>26.5</td>
                                  <td>27.5</td>
                                  <td>28.5</td>
                                </tr>
                                <tr>
                                  <th>Length</th>
                                  <td>20</td>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="table10 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Boys - Dungree Half Pant
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>10</td>
                                  <td>11</td>
                                  <td>12</td>
                                  <td>13</td>
                                  <td>14</td>
                                  <td>15</td>
                                </tr>
                                <tr>
                                  <th>Length</th>
                                  <td>15</td>
                                  <td>16</td>
                                  <td>17</td>
                                  <td>18</td>
                                  <td>19</td>
                                  <td>20</td>
                                </tr>
                                <tr>
                                  <th>Waist</th>
                                  <td>21</td>
                                  <td>22</td>
                                  <td>23</td>
                                  <td>24</td>
                                  <td>25</td>
                                  <td>26</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="hosiery-tab-pane"
                      role="tabpanel"
                      aria-labelledby="hosiery-tab"
                      tabindex="0"
                    >
                      <div className="tables">
                        <div className="table3 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="15">
                                    Hosiery - Track Pants
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>18</td>
                                  <td>20</td>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                  <td>44</td>
                                </tr>
                                <tr>
                                  <th>Length</th>
                                  <td>18</td>
                                  <td>20</td>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                  <td>44</td>
                                </tr>

                                <tr>
                                  <th>
                                    Waist Relaxed <br />
                                    <span>(Back Elastic)</span>
                                  </th>
                                  <td>16</td>
                                  <td>17</td>
                                  <td>18</td>
                                  <td>19</td>
                                  <td>20</td>
                                  <td>21</td>
                                  <td>22</td>
                                  <td>23</td>
                                  <td>24</td>
                                  <td>25</td>
                                  <td>26</td>
                                  <td>27</td>
                                  <td>28</td>
                                  <td>30</td>
                                </tr>
                                <tr>
                                  <th>
                                    Waist Extended <br />
                                    <span>(Back Elastic)</span>
                                  </th>
                                  <td>21</td>
                                  <td>22</td>
                                  <td>23</td>
                                  <td>24</td>
                                  <td>25</td>
                                  <td>26</td>
                                  <td>27</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>31</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="tables">
                        <div className="table2 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Hosiery - PPT - Shirts
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>18</td>
                                  <td>20</td>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                </tr>
                                <tr>
                                  <th>Chest</th>
                                  <td>18</td>
                                  <td>20</td>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                </tr>

                                <tr>
                                  <th>Length</th>
                                  <td>14</td>
                                  <td>15.5</td>
                                  <td>17</td>
                                  <td>18</td>
                                  <td>20</td>
                                  <td>21</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="tables">
                        <div className="table2 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Hosiery - Hoodies
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>20</td>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                </tr>
                                <tr>
                                  <th>Chest</th>
                                  <td>20</td>
                                  <td>22</td>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                </tr>

                                <tr>
                                  <th>Length</th>
                                  <td>16.5</td>
                                  <td>17.5</td>
                                  <td>19</td>
                                  <td>20.5</td>
                                  <td>21.5</td>
                                  <td>22.5</td>
                                  <td>23.5</td>
                                  <td>24.5</td>
                                  <td>25.5</td>
                                  <td>26.5</td>
                                  <td>27</td>
                                  <td>27.5</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="tables">
                        <div className="table2 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Hosiery - Nehru Jacket
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                  <td>44</td>
                                </tr>
                                <tr>
                                  <th>Chest</th>
                                  <td>24</td>
                                  <td>26</td>
                                  <td>28</td>
                                  <td>30</td>
                                  <td>32</td>
                                  <td>34</td>
                                  <td>36</td>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                  <td>44</td>
                                </tr>

                                <tr>
                                  <th>Length</th> <td>18</td>
                                  <td>19</td>
                                  <td>20</td>
                                  <td>21</td>
                                  <td>22</td>
                                  <td>23</td>
                                  <td>24</td>
                                  <td>25</td>
                                  <td>26</td>
                                  <td>27</td>
                                  <td>28</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="accessory-tab-pane"
                      role="tabpanel"
                      aria-labelledby="accessory-tab"
                      tabindex="0"
                    >
                      <div className="tables">
                        <div className="table2 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Accessory - Belt
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td>30</td>
                                  <td>36</td>
                                  <td>42</td>
                                </tr>
                                <tr>
                                  <th>Waist</th>
                                  <td>30</td>
                                  <td>36</td>
                                  <td>42</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div className="tables">
                        <div className="table2 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Accessory - Socks
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>
                                  <td className="text-center">XS</td>
                                  <td className="text-center">S</td>
                                  <td className="text-center">M</td>
                                  <td className="text-center">L</td>
                                  <td className="text-center">XL</td>
                                  <td className="text-center">XXL</td>
                                </tr>
                                <tr>
                                  <th>Age</th>
                                  <td>
                                    3 to 4 <br /> Years{" "}
                                  </td>
                                  <td>
                                    5 to 7 <br /> Years{" "}
                                  </td>
                                  <td>
                                    8 to 10 <br /> Years{" "}
                                  </td>
                                  <td>
                                    11 to 13 <br /> Years{" "}
                                  </td>
                                  <td>
                                    13 to 15 <br /> Years{" "}
                                  </td>
                                  <td>
                                    16 to 18 <br /> Years{" "}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div className="tables">
                        <div className="table2 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Accessory With Bottom Content - Shoe Size
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>UK/India</th>
                                  <td>6</td>
                                  <td>7</td>
                                  <td>8</td>
                                  <td>9</td>
                                  <td>10</td>
                                </tr>
                                <tr>
                                  <th>
                                    Length <br /> (in cm)
                                  </th>
                                  <td>26.4</td>
                                  <td>27.3</td>
                                  <td>27.9</td>
                                  <td>28.5</td>
                                  <td>28.9</td>
                                </tr>
                                <tr>
                                  <th>Brand size</th>
                                  <td>6</td>
                                  <td>7</td>
                                  <td>8</td>
                                  <td>9</td>
                                  <td>10</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div className="tables">
                        <div className="table2 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Accessory With Bottom Content - Shoe Size
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="text-center">
                                <tr>
                                  <td>7C</td>
                                  <td>8C</td>
                                  <td>9C</td>
                                  <td>10C</td>
                                  <td>11C</td>
                                  <td>12C</td>
                                  <td>13C</td>
                                  <td>1</td>
                                  <td>2</td>
                                  <td>3</td>
                                  <td>4</td>
                                  <td>5</td>
                                </tr>
                                <tr>
                                  <td>
                                    3<br /> Years{" "}
                                  </td>
                                  <td>
                                    3.5 <br /> Years{" "}
                                  </td>
                                  <td>
                                    4 <br /> Years{" "}
                                  </td>
                                  <td>
                                    4.5 <br /> Years{" "}
                                  </td>
                                  <td>
                                    5 <br /> Years{" "}
                                  </td>
                                  <td>
                                    5.5 <br /> Years{" "}
                                  </td>
                                  <td>
                                    6 <br /> Years{" "}
                                  </td>
                                  <td>
                                    7-8 <br /> Years{" "}
                                  </td>
                                  <td>
                                    8-9 <br /> Years{" "}
                                  </td>
                                  <td>
                                    9-10 <br /> Years{" "}
                                  </td>
                                  <td>
                                    10-11 <br /> Years{" "}
                                  </td>
                                  <td>
                                    11-12 <br /> Years{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td>14.6</td>
                                  <td>15.6</td>
                                  <td>16.5</td>
                                  <td>17.1</td>
                                  <td>18.1</td>
                                  <td>19.1</td>
                                  <td>19.7</td>
                                  <td>20.6</td>
                                  <td>21.6</td>
                                  <td>22.2</td>
                                  <td>23.2</td>
                                  <td>24.1</td>
                                </tr>
                                <tr>
                                  <td>7C</td>
                                  <td>8C</td>
                                  <td>9C</td>
                                  <td>10C</td>
                                  <td>11C</td>
                                  <td>12C</td>
                                  <td>13C</td>
                                  <td>1</td>
                                  <td>2</td>
                                  <td>3</td>
                                  <td>4</td>
                                  <td>5</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade modal-school-uniform"
          id="sizeguideIndustrial"
          tabindex="-1"
          aria-labelledby="size-guide"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl">
            <div class="modal-content w-100">
              <div class="modal-header">
                <h2 class="modal-title fs-5" id="size-gide">
                  Industrial Uniform Size Guide
                </h2>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div className="tabs">
                  {/* <ul class="nav nav-tabs" id="myTab" role="tablist">

                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#girls-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="girls-tab-pane"
                        aria-selected="true"
                      >

                        Uniforms
                      </button>
                    </li>
                  </ul> */}
                  <div class="tab-content" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="girls-tab-pane"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                      tabindex="0"
                    >
                      <div className="tables">
                        <div className="table1 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Industrial Uniform
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Size</th>

                                  <th>Length</th>
                                  <th>Shoulder</th>
                                  <th>Chest</th>
                                  <th>Sleev Length</th>
                                </tr>
                                <tr>
                                  <th>S</th>
                                  <td>58.0 inch</td>
                                  <td>18.5 inch</td>
                                  <td>43.0 inch</td>
                                  <td>22.5 inch</td>
                                </tr>
                                <tr>
                                  <th>M</th>
                                  <td>59.5 inch</td>
                                  <td>19.5 inch</td>
                                  <td>45.0 inch</td>
                                  <td>23.0 inch</td>
                                </tr>{" "}
                                <tr>
                                  <th>L</th>
                                  <td>61.0 inch</td>
                                  <td>20.5 inch</td>
                                  <td>47.0 inch</td>
                                  <td>23.5 inch</td>
                                </tr>{" "}
                                <tr>
                                  <th>XL</th>
                                  <td>61.0 inch</td>
                                  <td>21.5 inch</td>
                                  <td>49.0 inch</td>
                                  <td>24 inch</td>
                                </tr>{" "}
                                <tr>
                                  <th>XXL</th>
                                  <td>64.0 inch</td>
                                  <td>22.5 inch</td>
                                  <td>51.0 inch</td>
                                  <td>24.5 inch</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade modal-school-uniform"
          id="sizeguideMens"
          tabindex="-1"
          aria-labelledby="size-guide"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl">
            <div class="modal-content w-100">
              <div class="modal-header">
                <h2 class="modal-title fs-5" id="size-gide">
                  Mens T-Shirt Size Guide
                </h2>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div className="image-outer">
                  <img src={industries}></img>
                </div>{" "}
                <div className="tabs">
                  <div class="tab-content" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="mens-tab-pane"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                      tabindex="0"
                    >
                      <div className="tables">
                        <div className="table1 table-item">
                          <div className="table">
                            <table>
                              <thead>
                                <tr className="bg-primary text-center text-white">
                                  <th className="fw-500 px-2 py-1" colspan="14">
                                    Men's Size Guide
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th></th>
                                  <th>S</th>
                                  <th>M</th>
                                  <th>L</th>
                                  <th>XL</th>
                                  <th>2XL</th>
                                  <th>3XL</th>
                                </tr>
                                <tr>
                                  <th>Chest</th>
                                  <td>38</td>
                                  <td>40</td>
                                  <td>42</td>
                                  <td>44</td>
                                  <td>46</td>
                                  <td>48</td>
                                </tr>
                                <tr>
                                  <th>Length</th>
                                  <td>26</td>
                                  <td>27</td>
                                  <td>28</td>
                                  <td>29</td>
                                  <td>30</td>
                                  <td>31</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SizeGuide;
