import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../../component/header/header";
import { useNavigate } from "react-router-dom";
import "./createBlog.scss";

const CreateBlog = () => {
  const [inputHandler, setInputHandler] = useState({
    title: " ",
    content: " ",
    readingTime: " ",
  });
  const [image, setImage] = useState([]);

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const navigate = useNavigate();
  const [tags, setTags] = useState([]);

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token) {
    } else {
      history("/login");
    }
  }, []);

  const onChangeInputHandler = (e) => {
    const { name, value } = e.target;
    setInputHandler(() => {
      return { ...inputHandler, [name]: value };
    });
  };
  const history = useNavigate();
  const backToDashboard = () => {
    history("/dashboard");
  };

  const addTags = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      setTags([...tags, event.target.value]);
      event.target.value = "";
    }
  };

  const removeTags = (index) => {
    setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    const { title, content, readingTime } = inputHandler;

    if (title.trim() === "") {
      alert("Add Title");
      return;
    } else if (content.trim() === "") {
      alert("Add Content");
      return;
    } else if (readingTime.trim() === "") {
      alert("Add Reading Time");
      return;
    } else if (!image || image.length === 0) {
      alert("Add Image");
      return;
    }

    try {
      if (!Array.isArray(image) || image.length === 0) {
        alert("Add Image");
        return;
      }

      // Upload images to Cloudinary in parallel
      const uploadPromises = image.map(async (img) => {
        if (img instanceof File || img instanceof Blob) {
          const formData = new FormData();
          formData.append("file", img);
          formData.append("upload_preset", "Aryan_Testing"); // Replace with your Cloudinary preset

          try {
            const { data } = await axios.post(
              "https://api.cloudinary.com/v1_1/dqvnizdtk/image/upload",
              formData
            );
            return data.secure_url || null;
          } catch (error) {
            console.error("Cloudinary Upload Error:", error);
            return null;
          }
        } else {
          console.error("Invalid image format:", img);
          return null;
        }
      });

      const uploadedImageUrls = (await Promise.all(uploadPromises)).filter(
        Boolean
      );

      if (uploadedImageUrls.length === 0) {
        alert("Image upload failed");
        return;
      }

      console.log("Uploaded Images:", uploadedImageUrls);

      // Blog post request after successful Cloudinary upload
      const blogResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}blogsection/composeBlog`,
        {
          title,
          content,
          readingTime,
          image: uploadedImageUrls[0], // Use the first uploaded image URL
          tags,
        }
      );

      if (blogResponse?.data?.success) {
        console.log("Blog added successfully");
        navigate("/dashboard");

        // Reset form
        setInputHandler({
          ...inputHandler,
          title: "",
          content: "",
          readingTime: "",
        });

        setImage(null);
      } else {
        console.error("Failed to add blog:", blogResponse.data);
        alert("Failed to add blog");
      }
    } catch (error) {
      console.error("Error during submission:", error);
      alert("Something went wrong. Please try again.");
    }
  };

  return (
    <>
      <div className="createblog">
        <Header />
        <section>
          {
            <div className="form_data">
              <div className="cross" onClick={backToDashboard}>
                <div className="cross-inner">
                  <i class="bi bi-arrow-left"></i>
                </div>
                <h4>Back</h4>
              </div>
              <div className="form_heading">
                <h1>Create Blog</h1>
              </div>

              <form>
                <div className="form_input">
                  <label htmlFor="title">Title</label>
                  <input
                    type="text"
                    onChange={onChangeInputHandler}
                    value={inputHandler.title}
                    id="title"
                    name="title"
                    placeholder="Title"
                  />
                </div>
                <div className="form_input">
                  <label htmlFor="Content">Content</label>
                  <textarea
                    style={{ width: "100%" }}
                    rows={10}
                    type="text"
                    onChange={onChangeInputHandler}
                    value={inputHandler.content}
                    id="content"
                    name="content"
                    placeholder="Content ...."
                  />
                </div>
                <div className="form_input">
                  <label htmlFor="readingTime">Estimate Reading Time</label>
                  <input
                    type="readingTime"
                    onChange={onChangeInputHandler}
                    value={inputHandler.readingTime}
                    id="readingTime"
                    name="readingTime"
                    placeholder="readingTime"
                  />
                </div>
                <div className="form_input">
                  <label htmlFor="productImages">Resource Images</label>
                  <input
                    type="file"
                    id="productImages"
                    multiple
                    onChange={(e) => setImage([...image, ...e.target.files])}
                    name="productImages"
                    accept="image/*"
                    required
                  />
                </div>

                <div className="tags">
                  <label>Colors</label>
                  <div className="tags-input-container">
                    {tags.map((tag, index) => (
                      <div className="tag-item" key={index}>
                        <span className="text">{tag}</span>
                        <span
                          className="close"
                          onClick={() => removeTags(index)}
                        >
                          &times;
                        </span>
                      </div>
                    ))}
                    <input
                      onKeyDown={(event) => addTags(event)}
                      type="text"
                      className="tags-input"
                      placeholder="Please enter your tags here"
                    />
                  </div>
                </div>
                <div className="btn" onClick={onSubmitHandler}>
                  Create Blog
                </div>
              </form>
            </div>
          }
        </section>
      </div>
    </>
  );
};

export default CreateBlog;
