import React, { useState } from "react";
import "./dashboard.scss";
import Header from "../../component/header/header";
import Footer from "../../component/footer/footer";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
  maxHeight: "80vh",
};
const Dashboard = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [products, setProducts] = useState([]);
  const [categoryName, setCategoryName] = useState("uniform");
  const [contactDetail, setContactDetail] = useState();
  const [enquiryDetails, setEnquiryDetails] = useState();
  const [productContactDetail, setProductContactDetail] = useState();
  const [open, setOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const handleOpen = (enquiry) => {
    setSelectedEnquiry(enquiry);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [radioValue, setRadioValue] = useState("yes");
  const [selectedEnquiry, setSelectedEnquiry] = useState(null);
  const token = localStorage.getItem("token");
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  }, []);

  const createProduct = () => {
    navigate("/createProduct");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const uniformHandler = () => {
    setSearchTerm("");
    setValue(0);
    setCategoryName("uniform");
  };
  // const giftHandler = () => {
  //   setSearchTerm("");
  //   setValue(1);
  //   setCategoryName("Coorporate Gifting");
  // };

  const enquiryHandler = () => {
    setSearchTerm("");
    setValue(2);
  };
  const ContactUsDetailsHandler = () => {
    setSearchTerm("");
    setValue(3);
  };

  const ProductContactDetailsHandler = () => {
    setSearchTerm("");
    setValue(5);
  };

  const BlogHandler = () => {
    setSearchTerm("");
    setValue(4);
  };

  const [inputHandler, setInputHandler] = useState({
    name: "",
    number: "",
    companyName: "",
    industry: "",
    customShirt: "",
    designTheme: "",
    designColor: "",
    designLocation: "",
    envision: "",
    shirtStyle: "",
    shirtMaterial: "",
    shirtCount: "",
  });

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  const onChangeInputHandler = (event) => {
    setInputHandler({
      ...inputHandler,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_BASE_URL + `product/${categoryName}`
        );
        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            const flattenedProducts = data.data.subcategories.flatMap(
              (subcategory) => {
                return subcategory.products.map((product) => ({
                  ...product,
                  subcategoryName: subcategory.name,
                }));
              }
            );
            setProducts(flattenedProducts);
          } else {
            console.log(data.message);
          }
        } else {
          throw new Error("Product not found");
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchProduct();
  }, [categoryName]);

  console.log(products);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_BASE_URL);
        if (response.ok) {
          const data = await response.json();
          setContactDetail(data.data);
        } else {
          console.error("Error fetching data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_BASE_URL + `productContact/getContact`
        );
        if (response.ok) {
          const data = await response.json();
          setProductContactDetail(data.data);
        } else {
          console.error("Error fetching data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_BASE_URL + `productContact/getContact`
        );
        if (response.ok) {
          const data = await response.json();
          setProductContactDetail(data.data);
        } else {
          console.error("Error fetching data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_BASE_URL + `enquiry`
        );
        if (response.ok) {
          const data = await response.json();
          setEnquiryDetails(data.data);
        } else {
          console.error("Error fetching data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const onLogOut = () => {
    console.log("clicked");
    confirmAlert({
      title: "Logout",
      message: `Leaving so soon? Would hate to see you go!`,
      buttons: [
        {
          label: "OK",
          onClick: () => {
            // Clear local storage and navigate to the login page only after the user confirms
            localStorage.clear();
            //  window.location.reload(); // Force refresh
            window.location.href = "/login"; // Directly navigate to the login page
          },
        },
        {
          label: "Cancel",
          onClick: () => {
            navigate("/dashboard");
            // Do nothing if the user clicks 'Cancel'
          },
        },
      ],
    });
  };

  const deleteHandler = (id) => {
    confirmAlert({
      title: "Delete Product",
      message: `Do you really want to delete this product?`,
      buttons: [
        {
          label: "OK",
          onClick: () => {
            const data = axios
              .delete(process.env.REACT_APP_BASE_URL + `deleteProduct/${id}`)
              .then(() => {
                console.log("product deleted successfully");
                window.location.reload();
              })
              .catch((error) => {
                console.log(error);
              });
          },
        },
        {
          label: "Cancel",
          onClick: () => {
            navigate("/dashboard");
            // Do nothing if the user clicks 'Cancel'
          },
        },
      ],
    });
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredProducts = products.filter(
    (product) =>
      product.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.subcategoryName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  console.log(filteredProducts);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_BASE_URL + `blogsection/blogs`
        );
        if (response.data.success) {
          setBlogs(response.data.data);
        } else {
          console.log(response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchBlogs();
  }, []);

  const filteredBlogs = blogs.filter((blog) =>
    blog.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const createBlog = () => {
    navigate("/createBlog");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const deleteBlogHandler = (id, title) => {
    confirmAlert({
      title: "Delete Blog",
      message: `Do you really want to delete ${title}?`,
      buttons: [
        {
          label: "OK",
          onClick: () => {
            const data = axios
              .delete(
                process.env.REACT_APP_BASE_URL + `blogsection/deleteBlog/${id}`
              )
              .then(() => {
                window.location.reload();
              })
              .catch((error) => {
                console.log(error);
              });
          },
        },
        {
          label: "Cancel",
          onClick: () => {
            navigate("/dashboard");
            // Do nothing if the user clicks 'Cancel'
          },
        },
      ],
    });
  };

  const editBlogHandler = (id) => {
    navigate(`/editBlog/${id}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const editProductHandler = (id) => {
    console.log(id);
    navigate(`/editProduct/${id}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="dashboard-main">
        <div className="dashboard-hero-img-section">
          <Header />
          <div className="dashboard-main-hero-content-section">
            <h1 className="dashboard-content">Dashboard</h1>
          </div>
        </div>
        <div className="dashboard-header row">
          <div
            className={`col dash-inner ${value === 0 ? "active-tab" : ""}`}
            onClick={uniformHandler}
          >
            <div className="gift">
              <p> Uniform </p>
            </div>
          </div>
          <div className={`col dash-inner ${value === 2 ? "active-tab" : ""}`}>
            <div className="gift" onClick={enquiryHandler}>
              <p> Get Your T-shirt</p>
            </div>
          </div>
          <div className={`col dash-inner ${value === 5 ? "active-tab" : ""}`}>
            <div
              className="gift text-center"
              onClick={ProductContactDetailsHandler}
            >
              <p> Product enquiry</p>
            </div>
          </div>
          <div className={`col dash-inner ${value === 3 ? "active-tab" : ""}`}>
            <div className="gift" onClick={ContactUsDetailsHandler}>
              <p> Contact Form</p>
            </div>
          </div>

          <div className={`col dash-inner ${value === 4 ? "active-tab" : ""}`}>
            <div className="gift" onClick={BlogHandler}>
              <p> Blogs</p>
            </div>
          </div>
          <div className={`col dash-inner`}>
            <div className="logout" onClick={onLogOut}>
              <p>Logout</p>
            </div>
          </div>
        </div>
        {value == 0 && (
          <div className="dashboard-value0">
            <div className="p-4 d-flex align-items-center justify-content-between dashboard-value0-outer">
              <button className="create-product" onClick={createProduct}>
                Create
              </button>
              <input
                type="text"
                className="style"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search Results"
              />
            </div>
          </div>
        )}

        {value === 0 && (
          <div className="dashboard-container-uniform">
            <div className="product">
              <div className="about-product-head w-100 row">
                <div className="product-image col-md-3 col-2">
                  <div className="image-head d-flex justify-content-center">
                    <h6>IMAGE</h6>
                  </div>
                </div>
                <div className="product-name-unit row col-md-4 col-3">
                  <div className="product-name col-6">
                    <h6>NAME</h6>
                  </div>
                  <div className="product-unit col-6">
                    <h6>DESCRIPTION</h6>
                  </div>
                </div>
                <div className="product-date-color row col-md-3 col-6">
                  <div className="product-category col-6">
                    <h6>CATEGORY</h6>
                  </div>
                  <div className="product-color col-6">
                    <h6>COLOR</h6>
                  </div>
                </div>
                <div className="product-action col-md-2 col-1">
                  <div className="action">
                    <h6>ACTION</h6>
                  </div>
                </div>
              </div>
              <hr className="line-main-head" />

              {filteredProducts.length === 0 ? (
                <div className="about-no-product-main">
                  <p>No Product found.</p>
                </div>
              ) : (
                <div className="about-product-main">
                  {filteredProducts.map((item, index) => {
                    return (
                      <div className="about-product" key={index}>
                        <div className="product-image col-md-3 col-2">
                          <div className="product-image-outer d-flex justify-content-center">
                            {item && (
                              <img
                                src={
                                  item?.frontImagess
                                    ? item?.frontImagess[0]
                                    : item?.frontImage
                                }
                                alt=""
                              />
                            )}
                          </div>
                        </div>

                        <div className="product-name-description row col-md-4 col-3">
                          <div className="product-name col-6">
                            <p>{item?.title}</p>
                          </div>
                          <div className="product-description col-6">
                            <div className="product-description">
                              {item?.description.length > 15 ? (
                                <p>{item?.description.substring(0, 15)}...</p>
                              ) : (
                                <p>{item?.description}</p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="product-category-color row col-md-3 col-6">
                          <div className="product-category col-6">
                            <p>{item?.subcategoryName}</p>
                          </div>
                          <div className="product-color row col-6">
                            {item?.colors.map((color, colorIndex) => (
                              <div
                                key={colorIndex}
                                className="color-outer col-4"
                              >
                                <div
                                  className="color"
                                  style={{
                                    backgroundColor: color,
                                  }}
                                ></div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="product-action col-md-2 col-1">
                          <div className="action-button d-flex flex-column">
                            <button
                              className="delete-item"
                              onClick={() => {
                                deleteHandler(item._id);
                              }}
                            >
                              <i className="bi bi-trash"></i>
                              <div className="delete-text">Delete</div>
                            </button>
                            <button
                              className="edit-item"
                              onClick={() => {
                                editProductHandler(item._id);
                              }}
                            >
                              <i className="bi bi-pencil-square"></i>
                              <div className="edit-text">Edit</div>
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              <hr className="line-main" />
            </div>
          </div>
        )}

        {value == 4 && (
          <div className="dashboard-value0">
            <div className="p-4 d-flex align-items-center justify-content-between dashboard-value0-outer">
              <button className="create-product" onClick={createBlog}>
                Create
              </button>
              <input
                type="text"
                className="style"
                placeholder="Search Results"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        )}
        {value === 2 && (
          <div className="dashboard-container-enquiry">
            <div className="responses">
              <div className="resonse-head w-100 m-0 row">
                <div className="response-name col-md-2 col-3">
                  <div className="name-head d-flex justify-content-center">
                    <h6>NAME</h6>
                  </div>
                </div>
                <div className="response-contact col-md-2 col-3">
                  <div className="name-head d-flex justify-content-center">
                    <h6>CONTACT</h6>
                  </div>
                </div>
                <div className="response-purpose col-md-2  ">
                  <div className="name-head d-flex justify-content-center">
                    <h6>PURPOSE</h6>
                  </div>
                </div>
                <div className="response-company col-md-2 ">
                  <div className="name-head d-flex justify-content-center">
                    <h6>COMPANY</h6>
                  </div>
                </div>
                <div className="response-quantity col-md-2 col-3">
                  <div className="name-head d-flex justify-content-center">
                    <h6>QUANTITY</h6>
                  </div>
                </div>
                <div className="response-details col-md-2 col-3">
                  <div className="name-head d-flex justify-content-center">
                    <h6>DETAILS</h6>
                  </div>
                </div>
              </div>
              <hr className="line-main-head" />
              {enquiryDetails?.map((item, index) => {
                return (
                  <div className="about-response row m-0" key={index}>
                    <div className="about-response-name-outer col-md-2 col-3">
                      <div className="about-response-name ">
                        <p>{item.name}</p>
                      </div>
                    </div>
                    <div className="about-response-contact-outer col-md-2 col-3">
                      <div className="about-response-contact ">
                        <p>{item.number}</p>
                      </div>
                    </div>
                    <div className="about-response-purpose-outer col-2">
                      <div className="about-response-purpose ">
                        <p>{item.customShirt}</p>
                      </div>
                    </div>
                    <div className="about-response-company-outer col-2">
                      <div className="about-response-company ">
                        <p>{item.companyName}</p>
                      </div>
                    </div>
                    <div className="about-response-quantity-outer col-md-2 col-3">
                      <div className="about-response-quantity ">
                        <p>{item.shirtCount}</p>
                      </div>
                    </div>
                    <div className="about-response-details-outer col-md-2 col-3">
                      <div className="about-response-details d-flex flex-column">
                        <button
                          className="view-item btn "
                          onClick={() => {
                            handleOpen(item);
                          }}
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          data-bs-whatever="@mdo"
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
              <hr className="line-main" />
            </div>
          </div>
        )}
        {value === 3 && (
          <div className="dashboard-container-contact">
            <div className="responses">
              <div className="resonse-head w-100 m-0 row">
                <div className="response-name col-3">
                  <div className="name-head d-flex justify-content-center">
                    <h6>NAME</h6>
                  </div>
                </div>
                <div className="response-contact col-3">
                  <div className="name-head d-flex justify-content-center">
                    <h6>EMAIL</h6>
                  </div>
                </div>
                <div className="response-purpose col-3">
                  <div className="name-head d-flex justify-content-center">
                    <h6>MESSAGE</h6>
                  </div>
                </div>
                <div className="response-purpose col-3">
                  <div className="name-head d-flex justify-content-center">
                    <h6>View</h6>
                  </div>
                </div>
              </div>
              <hr className="line-main-head" />
              {contactDetail?.map((item, index) => {
                return (
                  <div key={index} className="about-response row">
                    <div className="about-response-name-outer col-3">
                      <div className="about-response-name ">
                        <p>{item?.name}</p>
                      </div>
                    </div>
                    <div className="about-response-contact-outer col-3">
                      <div className="about-response-contact ">
                        <p>
                          {item?.email.length > 15 ? (
                            <p>{item?.email.substring(0, 15)}...</p>
                          ) : (
                            <p>{item?.email}</p>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="about-response-purpose-outer col-3">
                      <div className="about-response-purpose ">
                        <p>
                          {item?.message.length > 10 ? (
                            <p>{item?.message.substring(0, 10)}...</p>
                          ) : (
                            <p>{item?.message}</p>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="about-response-name-outer col-3">
                      <div className="about-response-details ">
                        <button
                          type="button"
                          class="view-butt"
                          data-bs-toggle="modal"
                          data-bs-target={"#" + item?._id}
                        >
                          View
                        </button>

                        <div
                          class="modal fade"
                          id={item?._id}
                          data-bs-backdrop="static"
                          data-bs-keyboard="false"
                          tabindex="-1"
                          aria-labelledby="staticBackdropLabel"
                          aria-hidden="true"
                        >
                          <div class="modal-dialog">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h1
                                  class="modal-title fs-5"
                                  id="staticBackdropLabel"
                                >
                                  Details
                                </h1>
                                <button
                                  type="button"
                                  class="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div class="modal-body">
                                <div className="modal-text">
                                  <div className="modal-text-title">
                                    <div className="part">
                                      <div className="modal-head">
                                        <h6>Name</h6>
                                      </div>
                                      <div className="modal-inner-body">
                                        <p className="border p-1">
                                          {item?.name}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="part">
                                      <div className="modal-head">
                                        <h6>Email</h6>
                                      </div>
                                      <div className="modal-inner-body ">
                                        <p className="border p-1">
                                          {item?.email}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="part">
                                      <div className="modal-head">
                                        <h6>Message</h6>
                                      </div>
                                      <div className="modal-inner-body">
                                        <p className="border p-1">
                                          {item?.message}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="part">
                                      <div className="modal-head">
                                        <h6>Number</h6>
                                      </div>
                                      <div className="modal-inner-body">
                                        <p className="border p-1">
                                          {item?.number}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="modal-footer">
                                <button
                                  type="button"
                                  class="btn btn-secondary"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <hr className="line-main" />

              <hr className="line-main" />
            </div>
          </div>
        )}
        {value === 4 && (
          <div className="dashboard-container-blog">
            <div className="product">
              <div className="about-product-head w-100 row">
                <div className="product-image col-3 col-md-2">
                  <div className="image-head d-flex justify-content-center">
                    <h6>IMAGE</h6>
                  </div>
                </div>
                <div className="product-name-unit row col-5 col-md-8">
                  <div className="product-name col-6 d-flex align-items-center justify-content-center">
                    <h6>NAME</h6>
                  </div>
                  <div className="product-unit col-6 d-flex align-items-center justify-content-center">
                    <h6>DESCRIPTION</h6>
                  </div>
                </div>

                <div className="product-action col-4 col-md-2 d-flex align-items-center justify-content-center">
                  <div className="action">
                    <h6>ACTION</h6>
                  </div>
                </div>
              </div>
              <hr className="line-main-head" />

              {filteredBlogs.length === 0 ? (
                <div className="about-no-product-main">
                  <p>No Blogs found.</p>
                </div>
              ) : (
                <div className="about-product-main">
                  {filteredBlogs.map((item, index) => {
                    return (
                      <div className="about-product">
                        <div className="product-image col-2 col-md-2 d-flex align-items-center justify-content-center">
                          <div className="product-image-outer d-flex justify-content-center align-items-center">
                            {item && <img src={item?.image} alt="" />}
                          </div>
                        </div>

                        <div className="product-name-description row col-7 col-md-8">
                          <div className="product-name col-6 d-flex align-items-center justify-content-center">
                            <p>{item?.title}</p>
                          </div>
                          <div className="product-description col-6 d-flex align-items-center justify-content-center">
                            <div className="product-description">
                              <p>{item?.content.substring(0, 90)}...</p>
                            </div>
                          </div>
                        </div>

                        <div className="product-action col-3 col-md-2 d-flex align-items-center justify-content-center">
                          <div className="action-button d-flex flex-column">
                            <button
                              className="delete-item"
                              onClick={() => {
                                deleteBlogHandler(item._id, item.title);
                              }}
                            >
                              <i className="bi bi-trash"></i>
                              <div className="delete-text">Delete</div>
                            </button>
                            <button
                              className="edit-item"
                              onClick={() => {
                                editBlogHandler(item._id);
                              }}
                            >
                              <i className="bi bi-pencil-square"></i>
                              <div className="edit-text">Edit</div>
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              <hr className="line-main" />
            </div>
          </div>
        )}
        {value === 5 && (
          <div className="dashboard-container-contact">
            <div className="responses">
              <div className="resonse-head m-0 w-100 row">
                <div className="response-name col-2">
                  <div className="name-head d-flex justify-content-center">
                    <h6>NAME</h6>
                  </div>
                </div>
                <div className="response-contact col-2">
                  <div className="name-head d-flex justify-content-center">
                    <h6>Contact</h6>
                  </div>
                </div>
                <div className="response-contact col-2">
                  <div className="name-head d-flex justify-content-center">
                    <h6>Product Name</h6>
                  </div>
                </div>
                <div className="response-purpose col-3">
                  <div className="name-head d-flex justify-content-center">
                    <h6>MESSAGE</h6>
                  </div>
                </div>
                <div className="response-purpose col-3">
                  <div className="name-head d-flex justify-content-center">
                    <h6>View</h6>
                  </div>
                </div>

                <hr />
                {productContactDetail?.map((item, index) => {
                  return (
                    <div key={index} className="about-response row">
                      <div className="about-response-name-outer col-2">
                        <div className="about-response-name ">
                          <p>{item?.name}</p>
                        </div>
                      </div>
                      <div className="about-response-contact-outer col-2">
                        <div className="about-response-contact ">
                          <p>{item?.number}</p>
                        </div>
                      </div>
                      <div className="about-response-purpose-outer col-2">
                        <div className="about-response-purpose ">
                          <p>{item?.productName.substring(0, 20)}</p>
                        </div>
                      </div>
                      <div className="about-response-purpose-outer col-3">
                        <div className="about-response-purpose ">
                          <p>
                            {item?.message.length > 7 ? (
                              <p>{item?.message.substring(0, 7)}...</p>
                            ) : (
                              <p>{item?.message}</p>
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="about-response-name-outer col-3">
                        <div className="about-response-name ">
                          <button
                            type="button"
                            class="view-butt"
                            data-bs-toggle="modal"
                            data-bs-target={"#" + item?._id}
                          >
                            View
                          </button>
                          <div
                            class="modal fade"
                            id={item?._id}
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabindex="-1"
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                          >
                            <div class="modal-dialog">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h1
                                    class="modal-title fs-5"
                                    id="staticBackdropLabel"
                                  >
                                    Details
                                  </h1>
                                  <button
                                    type="button"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div class="modal-body">
                                  <div className="modal-text">
                                    <div className="modal-text-title">
                                      <div className="part">
                                        <div className="modal-head">
                                          <h6>Name</h6>
                                        </div>
                                        <div className="modal-inner-body">
                                          <p className="border p-1">
                                            {item?.name}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="part">
                                        <div className="modal-head">
                                          <h6>Number</h6>
                                        </div>
                                        <div className="modal-inner-body ">
                                          <p className="border p-1">
                                            {item?.number}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="part">
                                        <div className="modal-head">
                                          <h6>Product Name</h6>
                                        </div>
                                        <div className="modal-inner-body">
                                          <p className="border p-1">
                                            {item?.productName}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="part">
                                        <div className="modal-head">
                                          <h6>Message</h6>
                                        </div>
                                        <div className="modal-inner-body">
                                          <p className="border p-1">
                                            {item?.message}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="modal-footer">
                                  <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        {selectedEnquiry && (
          <div className="modal-outer">
            <div
              class="modal fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">
                      Contact Us
                    </h1>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <form>
                      <div>
                        <h4>Personal and Company Details</h4>
                        <div class="mb-3">
                          <label for="recipient-name" class="col-form-label">
                            Name:
                          </label>
                          <input
                            type="text"
                            name="name"
                            className="w-100"
                            value={selectedEnquiry?.name || ""}
                            readOnly
                          />
                        </div>
                        <div class="mb-3">
                          <label for="recipient-name" class="col-form-label">
                            Contact Number:
                          </label>
                          <input
                            type="text"
                            name="name"
                            className="w-100"
                            value={selectedEnquiry?.number || ""}
                            readOnly
                          />
                        </div>
                        <div class="mb-3">
                          <label for="recipient-name" class="col-form-label">
                            Company Name:
                          </label>
                          <input
                            type="text"
                            name="name"
                            className="w-100"
                            value={selectedEnquiry?.companyName || ""}
                            readOnly
                          />
                        </div>
                        <div class="mb-3">
                          <label for="recipient-name" class="col-form-label">
                            Industry:
                          </label>
                          <input
                            type="text"
                            name="name"
                            className="w-100"
                            value={selectedEnquiry?.industry || ""}
                            readOnly
                          />
                        </div>
                        <div class="mb-3">
                          <label for="recipient-name" class="col-form-label">
                            Company Logo:
                          </label>
                          {selectedEnquiry.image && (
                            <img
                              src={selectedEnquiry.image}
                              alt="Company Logo"
                              style={{ width: "100%", maxHeight: "200px" }}
                            />
                          )}
                        </div>
                      </div>
                      <hr />
                      <div>
                        <h4>Purpose of T-Shirts</h4>
                        <div class="mb-3">
                          <label for="recipient-name" class="col-form-label">
                            <h6>
                              What is the primary purpose of these custom
                              T-shirts? (e.g., team building, corporate events,
                              promotional giveaways, etc.)
                            </h6>
                          </label>
                          <input
                            type="text"
                            name="customShirt"
                            className="w-100"
                            value={selectedEnquiry?.customShirt || ""}
                            readOnly
                          />
                        </div>

                        <hr />

                        <div>
                          <h4>Design Theme And Preferences</h4>

                          <div class="mb-3">
                            <label for="recipient-name" class="col-form-label">
                              <h6>
                                Are there any design elements (e.g., text,
                                logos, slogans, icons) that should be included?
                                If yes, please provide details or attach the
                                same.
                              </h6>
                            </label>
                            <div>
                              <input
                                type="radio"
                                value="yes"
                                checked={radioValue === "yes"}
                                onChange={handleRadioChange}
                              />
                              <label>Yes</label>
                            </div>
                            <div>
                              <input
                                type="radio"
                                value="no"
                                checked={radioValue === "no"}
                                onChange={handleRadioChange}
                              />
                              <label>No</label>
                            </div>
                          </div>
                          {radioValue === "yes" && (
                            <input
                              type="text"
                              name="name"
                              className="w-100"
                              value={selectedEnquiry?.designTheme || ""}
                              readOnly
                            />
                          )}
                        </div>
                        <div class="mb-3">
                          <label for="recipient-name" class="col-form-label">
                            <h6>
                              Can you provide examples of T-shirt designs that
                              you like or dislike? (Attach links)
                            </h6>
                          </label>
                          <input
                            type="text"
                            name="designTShirt"
                            className="w-100"
                            value={selectedEnquiry?.designTShirt || ""}
                            readOnly
                          />
                        </div>
                        <div class="mb-3">
                          <label for="recipient-name" class="col-form-label">
                            <h6>
                              What are your preferred colors for the T-shirt
                              design?
                              <span style={{ color: "red" }}>*</span>
                            </h6>
                          </label>
                          <input
                            type="text"
                            name="designColor"
                            className="w-100"
                            value={selectedEnquiry?.designColor || ""}
                            readOnly
                          />
                        </div>
                        <div class="mb-3">
                          <label for="recipient-name" class="col-form-label">
                            <h6>
                              Are there any specific locations on the T-shirt
                              where you would like the logo to appear?
                            </h6>
                          </label>
                          <input
                            type="text"
                            name="designLocation"
                            className="w-100"
                            value={selectedEnquiry?.designLocation || ""}
                            readOnly
                          />
                        </div>
                        <div class="mb-3">
                          <label for="recipient-name" class="col-form-label">
                            <h6>
                              How would you describe the overall style or vibe
                              you envision for the custom T-shirts? (e.g.,
                              modern, minimalist, bold, playful)
                            </h6>
                          </label>
                          <input
                            type="text"
                            name="envision"
                            className="w-100"
                            value={selectedEnquiry?.envision || ""}
                            readOnly
                          />
                        </div>

                        <hr />
                        <div>
                          <h4>Style Preferences</h4>

                          <div class="mb-3">
                            <label for="recipient-name" class="col-form-label">
                              <h6>
                                What style of T-shirt do you prefer? (e.g., crew
                                neck, V-neck, polo, long sleeve, short sleeve)
                              </h6>
                            </label>
                            <input
                              type="text"
                              name="shirtStyle"
                              className="w-100"
                              value={selectedEnquiry?.shirtStyle || ""}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Dashboard;
