import react, { useState, useEffect } from "react";
import Header from "../../component/header/header";
import Footer from "../../component/footer/footer";
import "./category.scss";
import people from "../../assets/human.png";
import people1 from "../../assets/people1.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import { useNavigate } from "react-router-dom";
import "owl.carousel/dist/assets/owl.theme.default.css";
const Category = () => {
  const [categoryName, setCategoryName] = useState("Coorporate Gifting");
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_BASE_URL + `product/${categoryName}`
        );
        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            const flattenedProducts = data.data.subcategories.flatMap(
              (subcategory) => {
                return subcategory.products.map((product) => ({
                  ...product,
                  subcategoryName: subcategory.name,
                }));
              }
            );
            setProducts(flattenedProducts);
          } else {
            console.log(data.message);
          }
        } else {
          throw new Error("Product not found");
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchProduct();
  }, [categoryName]);
  console.log(products);

  const singleProductHandler = (event, id, title) => {
    event.preventDefault(); // Prevent default behavior if needed

    const formattedTitle = title.replace(/ /g, "_");
    const url = `/product/${formattedTitle}`;

    if (event.ctrlKey || event.metaKey) {
      // Open in a new tab if Ctrl (Windows) or Cmd (Mac) is pressed
      window.open(url, "_blank");
    } else {
      // Navigate normally and scroll to top
      navigate(url);
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_BASE_URL +
            `categories/${categoryName}/subcategories`
        );
        if (response.ok) {
          const data = await response.json();
          if (data) {
            // console.log(data)
            setCategory(data);
          } else {
            console.log(data.message);
          }
        } else {
          throw new Error("Product not found");
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchProduct();
  }, [categoryName]);

  return (
    <div className="category-main">
      <div className="category-hero-img-section">
        <Header />
        <div className="category-main-hero-content-section">
          <h1 className="category-content">Coorporate Gifting</h1>
        </div>
      </div>

      <div className="logo-carousel col-md-12">
        <OwlCarousel
          className="owl-theme"
          loop
          margin={10}
          autoplay="false"
          responsive={{
            0: {
              items: 1,
            },
            600: {
              items: 3,
            },
            1000: {
              items: 5,
            },
          }}
        >
          {categoryName == "Coorporate Gifting" ? (
            <div></div>
          ) : (
            <div>
              {category?.map((item, index) => {
                return (
                  <div key={indexedDB}>
                    <div className="item p-2">
                      <h4>{item?.name}</h4>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </OwlCarousel>
      </div>
      {products.map((product, index) => {
        if (index % 3 == 0)
          return (
            <div className="category-card-outer row" key={product._id}>
              <div className="category-card-inner row col-md-12">
                <div className="category-card-section-1 col-md-6">
                  <div
                    className="category-card-big row"
                    onClick={(e) => {
                      singleProductHandler(e, product?._id, product?.title);
                    }}
                  >
                    <div className="card-image col-md-6">
                      <div className="card-image-outer">
                        <div className="card-image-just-outer">
                          <img
                            src={
                              product?.frontImagess
                                ? product?.frontImagess[0]
                                : product?.frontImage
                            }
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card-about col-md-6">
                      <div className="card-about-outer">
                        <h4>{product?.title}</h4>
                        {product?.shortDescription.length > 100 ? (
                          <p>
                            {product?.shortDescription.substring(0, 100)}
                            ...
                          </p>
                        ) : (
                          <p>{product?.shortDescription}</p>
                        )}
                      </div>
                      <div className="more-div-button">
                        <button className="view-more-button">View More</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="category-card-section-2 col-md-6">
                  <div className="category-card-section-2-outer row">
                    {products[index + 1] && (
                      <div
                        className="category-card-small-1 col-md-12"
                        onClick={(e) => {
                          singleProductHandler(
                            e,
                            products[index + 1]?._id,
                            products[index + 1]?.title
                          );
                        }}
                      >
                        <div className="category-card-small-1-inner row">
                          <div className="small-image-1 col-md-5">
                            <img
                              src={
                                products[index + 1]?.frontImagess
                                  ? products[index + 1]?.frontImagess[0]
                                  : products[index + 1]?.frontImage
                              }
                              alt=""
                            />
                          </div>
                          <div className="small-about-1 col-md-7">
                            <div className="small-about-width-1">
                              <h4>{products[index + 1].title}</h4>
                              {products[index + 1].shortDescription.length >
                              100 ? (
                                <p>
                                  {products[
                                    index + 1
                                  ].shortDescription.substring(0, 100)}
                                  ...
                                </p>
                              ) : (
                                <p>{products[index + 1].shortDescription}</p>
                              )}
                            </div>
                            <div className="more-div-button">
                              <button className="view-more-button">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {products[index + 2] && (
                      <div
                        className="category-card-small-2 col-md-12"
                        onClick={(e) => {
                          singleProductHandler(
                            e,
                            products[index + 2]?._id,
                            products[index + 2]?.title
                          );
                        }}
                      >
                        <div className="category-card-small-2-inner row">
                          <div className="small-image-2 col-md-5">
                            <img
                              src={
                                products[index + 2]?.frontImagess
                                  ? products[index + 2]?.frontImagess[0]
                                  : products[index + 2]?.frontImage
                              }
                              alt=""
                            />
                          </div>
                          <div className="small-about-2 col-md-7">
                            <div className="small-about-width-2">
                              <h4>{products[index + 2].title}</h4>
                              {products[index + 1].shortDescription.length >
                              100 ? (
                                <p>
                                  {products[
                                    index + 1
                                  ].shortDescription.substring(0, 100)}
                                  ...
                                </p>
                              ) : (
                                <p>{products[index + 1].shortDescription}</p>
                              )}{" "}
                            </div>
                            <div className="more-div-button">
                              <button className="view-more-button">
                                View More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
      })}

      <Footer />
    </div>
  );
};

export default Category;
