import React from "react";
import "./CustomMerchandise.scss";
import Header from "../../component/header/header";
import Footer from "../../component/footer/footer";

import icon1 from "../../assets/merchandiseicon/1300455.png";
import icon2 from "../../assets/merchandiseicon/tracking.png";
import icon3 from "../../assets/merchandiseicon/9676560.webp";
import icon4 from "../../assets/merchandiseicon/images.jpeg";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// testi image
import testimonial1 from "../../assets/testimonial/testimonial1.jpg";
import testimonial2 from "../../assets/testimonial/testimonial2.jpg";
import testimonial3 from "../../assets/testimonial/testimonial3.jpg";
import testimonial4 from "../../assets/testimonial/testimonial4.jpg";
import testimonial5 from "../../assets/testimonial/testimonial5.jpg";
import testimonial6 from "../../assets/testimonial/testimonial6.jpg";
import testimonial1comma from "../../assets/testimonial/testimonial1comma.png";

import ban from "../../assets/NewBanner/01.png";
import firstPr from "../../assets/NewBanner/002.png";
import SecPr from "../../assets/NewBanner/003.png";
import thirtPr from "../../assets/NewBanner/004.png";
import fouPr from "../../assets/NewBanner/004.png";
import five from "../../assets/NewBanner/005.png";
import thhhh from "../../assets/NewBanner/333.png";

import lasban from "../../assets/NewBanner/001.png";
import { useNavigate } from "react-router-dom";
// import fivPr from "../../assets/NewBanner/005.png";

export default function CustomMerchandise() {
  const navigate = useNavigate();
  const handleExploreClick = (category) => {
    navigate(`/uniform-category/${category.replace(/\s+/g, "-")}`);
  };
  const productCategories = [
    {
      title: "Apparel",
      imge: firstPr,
      products: ["T-shirts", "Hoodies", "Polo shirts", "Jackets"],
      text: "Stylish and durable, our customizable apparel is perfect for teams, events, and everyday wear.",
    },
    {
      title: "Caps",
      imge: SecPr,
      products: ["Hats", "Beanies"],
      text: "Top off your branding with custom headgear made from sustainable materials.",
    },
    {
      title: "Bags and Accessories",
      imge: thhhh,
      products: ["Tote bags", "Backpacks", "Lanyards"],
      text: "Eco-friendly and functional – ideal for giveaways, events, and employee gifts.",
    },
    {
      title: "Event Merchandise",
      imge: five,
      products: ["Wristbands", "Badges", "Banners"],
      text: "Stand out at your next event with our customizable event merchandise.",
    },
    {
      title: "Sports and Activewear",
      imge: fouPr,
      products: ["Jerseys", "Track pants", "Gym towels"],
      text: "Performance-ready activewear designed for comfort and style.",
    },
  ];
  const customizationSteps = [
    {
      icon: icon4,
      step: "Choose Your Product",
      description: "Select from our wide range of merchandise.",
    },
    {
      icon: icon1,
      step: "Customize Your Design",
      description: "Upload your logo or artwork.",
    },
    {
      icon: icon3,
      step: "Approve the Preview",
      description: "Review and finalize your design.",
    },
    {
      icon: icon2,
      step: "Place Your Order",
      description: "Enjoy hassle-free bulk ordering.",
    },
  ];
  return (
    <div className="CustomMerchand">
      <div className="header">
        {" "}
        <Header />{" "}
      </div>
      <div className="heroSection">
        <div className="img">
          <img src={ban} alt="Custom Merchandise" />
        </div>
        <div className="heroTitle">
          <h1 className="brand text-center">
            Elevate Your Brand <br /> with <span>Custom Merchandise</span>
          </h1>
          <p>
            Get the perfect look for your event, with our customizable options.
          </p>
          <div className="cta-buttons">
            <button
              onClick={() => handleExploreClick("yourCategoryName")}
              className="flex-button"
            >
              Explore Products
            </button>
            <a
              href="https://wa.me/+918815190040?text=Your%20message%20here"
              target="_blank"
            >
              <button>Let's Talk</button>
            </a>
          </div>
        </div>
      </div>
      {/* second section */}
      <div className="uni-about">
        <section className="about-us">
          <h2>
            Why Choose <span>Unipride?</span>
          </h2>
          <p>
            At Unipride, we specialize in creating high-quality, eco-friendly
            merchandise tailored to your brand’s unique needs. From small
            startups to large corporations, our commitment to sustainability and
            innovation ensures you’ll receive products that represent your brand
            with style and responsibility.
          </p>
        </section>
        <ul className="ul">
          <li>Eco-Friendly Fabrics: Sustainable materials in every product.</li>
          <li>Custom Designs: Tailored to your specifications.</li>
          <li>Quick Turnaround: Timely delivery, every time.</li>
          <li>Bulk Discounts: Cost-effective solutions for all orders.</li>
        </ul>
      </div>
      {/* ..3... */}
      <section className="product-showcase-section">
        <h2>Our Products : Designed for Your Brand’s Success</h2>
        <div className="product-categories">
          {productCategories.map(({ title, products, text, imge }, index) => (
            <div key={index} className="product-category">
              <h4>{title}</h4>
              <p>{text}</p>
              <div className="overlay">
                <ul>
                  {products.map((product, idx) => (
                    <li key={idx}>{product}</li>
                  ))}
                </ul>
                <div className="image">
                  <img src={imge} alt="" />
                </div>
              </div>
              <div className="category-buttons">
                <a
                  href="https://wa.me/+918815190040?text=Your%20message%20here"
                  target="_blank"
                >
                  <button>Let's Talk</button>
                </a>
                <button
                  onClick={(event) => {
                    if (event.ctrlKey || event.metaKey || event.button === 1) {
                      const newTabUrl = "/contact";
                      window.open(newTabUrl, "_blank");
                    } else {
                      window.scrollTo(0, 0);
                      navigate("/contact");
                    }
                  }}
                >
                  Connect with E-mail
                </button>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* .....4...... */}
      <section className="sustainability-section">
        <div className="contentpara">
          <h2>Sustainability at Our Core</h2>
          <p>
            At Unipride, we believe in creating a better tomorrow. That’s why
            all our products are crafted from eco-friendly materials and
            manufactured using sustainable practices. Whether it’s organic
            cotton T-shirts or recycled fabric tote bags, we’re committed to
            reducing our environmental footprint.
          </p>
          <ul>
            <li>Sustainable materials.</li>
            <li>Eco-friendly packaging.</li>
            <li>Ethical manufacturing processes.</li>
          </ul>
        </div>
        <div className="image">
          <img src={lasban} alt="" />
        </div>
      </section>

      {/* ....5.... */}
      <section className="customization-process-section">
        <h2>How It Works</h2>
        <div className="steps">
          {customizationSteps.map((step, index) => (
            <div key={index} className="step">
              <img src={step.icon} alt="" />
              <div className="content">
                <h5>{step.step}</h5>
                <p>{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* ...6.. */}
      <div className="testimonial">
        <div className="container-main">
          <div className="heading">
            <h2>Testimonial</h2>
          </div>
          <div className="key-points d-flex justify-content-center">
            <OwlCarousel
              className="owl-theme"
              loop
              margin={10}
              autoplay="false"
              autoplayHoverPause={true}
              autoplayTimeout={100000000}
              responsive={{
                0: {
                  items: 1,
                },
                678: {
                  items: 2,
                },
                900: {
                  items: 3,
                },
                1200: {
                  items: 4,
                },
              }}
            >
              <div className="key-card">
                <div className="gapping row align-items-center justify-content-center">
                  <div className="card-upper-section">
                    <div className="card-upper-inner">
                      <img
                        src={testimonial1comma}
                        alt=" testimonial Unipride"
                      />
                    </div>
                  </div>
                  <div className="card-mid-section">
                    <div className="card-mid-inner">
                      <p>
                        As a tech startup, we sought workwear reflecting our
                        innovative spirit while maintaining cohesive branding.
                      </p>
                    </div>
                  </div>
                  <div className="card-bottom-section">
                    <div className="card-bottom-inner">
                      <div className="card-image">
                        <img src={testimonial4} alt="testimonial Unipride" />
                      </div>
                      <div className="card-name">
                        <div className="name-upper">
                          <p>Rishika Kothari</p>
                        </div>
                        {/* <div className="name-down">
                            <p>hey</p>
                          </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="key-card">
                <div className="gapping row align-items-center justify-content-center">
                  <div className="card-upper-section">
                    <div className="card-upper-inner">
                      <img src={testimonial1comma} alt="testimonial Unipride" />
                    </div>
                  </div>
                  <div className="card-mid-section">
                    <div className="card-mid-inner">
                      <p>
                        As a construction company, having durable and branded
                        workwear is crucial for our team's safety and
                        professionalism.
                      </p>
                    </div>
                  </div>
                  <div className="card-bottom-section">
                    <div className="card-bottom-inner">
                      <div className="card-image">
                        <img src={testimonial2} alt="testimonial Unipride" />
                      </div>
                      <div className="card-name">
                        <div className="name-upper">
                          <p>Samriddhi Acholiya</p>
                        </div>
                        {/* <div className="name-down">
                            <p>hey</p>
                          </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="key-card">
                <div className="gapping row align-items-center justify-content-center">
                  <div className="card-upper-section">
                    <div className="card-upper-inner">
                      <img src={testimonial1comma} alt="testimonial Unipride" />
                    </div>
                  </div>
                  <div className="card-mid-section">
                    <div className="card-mid-inner">
                      <p>
                        In our eyes, their attention to detail and commitment to
                        delivering exactly what we envisioned truly set them
                        apart.
                      </p>
                    </div>
                  </div>
                  <div className="card-bottom-section">
                    <div className="card-bottom-inner">
                      <div className="card-image">
                        <img src={testimonial1} alt="testimonial Unipride" />
                      </div>
                      <div className="card-name">
                        <div className="name-upper">
                          <p>Anmol Naik</p>
                        </div>
                        {/* <div className="name-down">
                            <p>hey</p>
                          </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="key-card">
                <div className="gapping row align-items-center justify-content-center">
                  <div className="card-upper-section">
                    <div className="card-upper-inner">
                      <img src={testimonial1comma} alt="testimonial Unipride" />
                    </div>
                  </div>
                  <div className="card-mid-section">
                    <div className="card-mid-inner">
                      <p>
                        It was great to see quality fabric being offered at such
                        reasonable prices compared to other brands and
                        e-commerce websites.
                      </p>
                    </div>
                  </div>
                  <div className="card-bottom-section">
                    <div className="card-bottom-inner">
                      <div className="card-image">
                        <img src={testimonial6} alt="testimonial Unipride" />
                      </div>
                      <div className="card-name">
                        <div className="name-upper">
                          <p>Aarohi Kardekar</p>
                        </div>
                        {/* <div className="name-down">
                            <p>hey</p>
                          </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="key-card">
                <div className="gapping row align-items-center justify-content-center">
                  <div className="card-upper-section">
                    <div className="card-upper-inner">
                      <img src={testimonial1comma} alt="testimonial Unipride" />
                    </div>
                  </div>
                  <div className="card-mid-section">
                    <div className="card-mid-inner">
                      <p>
                        As a hospitality business, maintaining a polished and
                        branded appearance is essential for our brand image.
                      </p>
                    </div>
                  </div>
                  <div className="card-bottom-section">
                    <div className="card-bottom-inner">
                      <div className="card-image">
                        <img src={testimonial5} alt="testimonial Unipride" />
                      </div>
                      <div className="card-name">
                        <div className="name-upper">
                          <p>Ayushi Yadav</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="key-card">
                <div className="gapping row align-items-center justify-content-center">
                  <div className="card-upper-section">
                    <div className="card-upper-inner">
                      <img src={testimonial1comma} alt="testimonial Unipride" />
                    </div>
                  </div>
                  <div className="card-mid-section">
                    <div className="card-mid-inner">
                      <p>
                        Unipride's customization and branding options set us
                        apart and leave a lasting impression on clients.
                      </p>
                    </div>
                  </div>
                  <div className="card-bottom-section">
                    <div className="card-bottom-inner">
                      <div className="card-image">
                        <img src={testimonial3} alt="testimonial Unipride" />
                      </div>
                      <div className="card-name">
                        <div className="name-upper">
                          <p>Khushi Yadav</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>
      {/* ....7..... */}
      <div class="accordion accordion-flush" id="accordionFlushExample">
        <h2>Frequently Asked Questions</h2>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              What is the minimum order quantity?
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              Our minimum order starts at just 50 units.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo"
              aria-expanded="false"
              aria-controls="flush-collapseTwo"
            >
              How long does delivery take?
            </button>
          </h2>
          <div
            id="flush-collapseTwo"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              Typically, orders are delivered within 10-14 business days.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree"
              aria-expanded="false"
              aria-controls="flush-collapseThree"
            >
              Can I see a sample before placing a bulk order?
            </button>
          </h2>
          <div
            id="flush-collapseThree"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              Absolutely! We offer sample previews for all products.
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
